// Class for interacting with server REST API
import {AppConfig} from 'app.config';
import {hasOwnProperty, OptionalExcept, OptionalIfNull, RequiredProp} from 'types';
import {DateTime, Duration} from 'luxon';
import {User, UserNotificationPref} from 'shared/auth.model';
import {BaySize, Garage, GarageBay, GarageBayScheduledWork} from 'shared/garage.model';
import {
    Appointment,
    ApptStatusCode,
    ApptWork,
    Classification,
    RedFlagTrack,
    Vehicle,
    VehicleOperatorAssignment,
    VendorWork,
} from 'shared/appt.model';

type RedFlagTrackOmit = Omit<RedFlagTrack, 'id' | 'update_time'>;
type VehicleOperatorAssignmentOmit = Omit<VehicleOperatorAssignment, 'id' | 'update_time'>;
type VehicleOperatorAssignmentCombine = VehicleOperatorAssignmentOmit & {previous_op: number | null};
type VendorWorkOmit = Omit<VendorWork, 'id' | 'update_time'>;
type ApptWorkOmit = Omit<ApptWork, 'id' | 'update_time'>;

interface RequestInitObjectHeaders {
    // force use of Object headers
    headers?: {[key: string]: string};
}

/** Like fetch, but throws errors on failure and tries to decode JSON responses. */
async function ezfetch(input: RequestInfo, init?: RequestInit & RequestInitObjectHeaders) {
    const resp = await fetch(input, init);

    const contentType = resp.headers.has('Content-Type')
        ? (resp.headers.get('Content-Type') as string)
        : 'application/json';
    // The server usually has its Content-Type set to 'application/json; charset=utf-8',
    // so use include() to check for content-type
    if (contentType.includes('application/json')) {
        // Decode as JSON and raise errors
        const data = (await resp.json()) as unknown;
        if (data && typeof data === 'object' && hasOwnProperty(data, 'error')) {
            const e: any = new Error(`${data.error}`);
            e.status = resp.status;
            throw e;
        } else if (!resp.ok && data && typeof data === 'string') {
            const e: any = new Error(data);
            e.status = resp.status;
            throw e;
        }
        return data as any;
    }
    if (!resp.ok) {
        const e: any = new Error(`${resp.status} ${resp.statusText}`);
        e.status = resp.status;
        throw e;
    }
    return await resp.text(); // TODO: Support blob, formData, arrayBuffer, etc content types in ezfetch
}

export class APIService {
    // Function to call when deauthenticated during an API call
    public static deauthCallback: (() => Promise<any>) | null = null;

    async getHeaders(): Promise<{[p: string]: string}> {
        const token = await AppConfig.getToken();
        if (token) return {Authorization: 'Bearer ' + token};
        else return {};
    }

    /** Like ezfetch, but merges in authentication headers */
    private async fetch(input: RequestInfo, init?: RequestInit & RequestInitObjectHeaders) {
        const headers = await this.getHeaders();
        if (init?.headers) init.headers = {...headers, ...init.headers};
        else if (init) init.headers = headers;
        else init = {headers};

        return await ezfetch(input, init).catch((err) => {
            if (err.status === 401 && APIService.deauthCallback) return APIService.deauthCallback();
            throw err;
        });
    }
    /** Get garage by id & associated bays */
    async getGarage(id: number): Promise<Garage> {
        const params = new URLSearchParams({id: id.toString()});
        return await this.fetch(`${AppConfig.API_BASE_URL}/garage?${params}`);
    }

    /** Get garage bay by ID, with associated garage */
    async getGarageBay(id: number): Promise<RequiredProp<GarageBay, 'garage'>> {
        const params = new URLSearchParams({id: id.toString()});
        return await this.fetch(`${AppConfig.API_BASE_URL}/garage/bay?${params}`);
    }

    /** Get garage bay by ID, with associated garage */
    async getSatelliteBays(id: number): Promise<GarageBay[]> {
        const params = new URLSearchParams({id: id.toString()});
        return await this.fetch(`${AppConfig.API_BASE_URL}/garage/satellite-bay?${params}`);
    }

    /** Get garage bay by ID, with associated garage */
    async getSatelliteBaysFromBay(id: number): Promise<GarageBay[]> {
        const params = new URLSearchParams({id: id.toString()});
        return await this.fetch(`${AppConfig.API_BASE_URL}/garage/satellite-bay-from-bay?${params}`);
    }

    /**
     * Get list of garages with an availability date/time for an appointment
     * Also allows filtering by distance
     * @param duration: Duration of desired appointment
     * @param bay_size: Required bay size for vehicle
     * @param default_garage: Default / preferred garage
     * @param [lat]: Optional latitude to use to measure garage distance from
     * @param [lon]: Optional longitude to use to measure garage distance from
     * @param [distance]: If `distance`, `lat`, `lon` all valid: Filters results by garages less than `distance` miles
     * @return: List of garages, supplemented with an ISO-8601 `available_time` for the earliest time it is available,
     * and (if applicable) the great-circle `distance` in miles from the provided location to the garage
     */
    async getAvailableGarages(
        duration: Duration,
        bay_size: BaySize,
        default_garage: number,
        lat?: number,
        lon?: number,
        distance?: number
    ): Promise<(Garage & {available_time: string; distance?: number})[]> {
        const params = new URLSearchParams({
            duration: duration.toISO(),
            bay_size,
            default_garage: default_garage.toString(),
        });
        lat !== undefined && params.append('lat', lat.toString());
        lon !== undefined && params.append('lon', lon.toString());
        distance !== undefined && params.append('distance', distance.toString());
        return await this.fetch(`${AppConfig.API_BASE_URL}/garage/available?${params}`);
    }

    /**
     * Get list of garages within the same state
     * @param duration: Duration of desired appointment
     * @param bay_size: Required bay size for vehicle
     * @param default_garage: Default / preferred garage
     * @param aepsatellite
     * @param [lat]: Optional latitude to use to measure garage distance from
     * @param [lon]: Optional longitude to use to measure garage distance from
     * @return: List of garages, supplemented with an ISO-8601 `available_time` for the earliest time it is available,
     * and (if applicable) the great-circle `distance` in miles from the provided location to the garage
     */
    async getChangeGarages(
        duration: Duration,
        bay_size: BaySize,
        default_garage: number,
        aepsatellite: number,
        lat?: number,
        lon?: number
    ): Promise<(Garage & {available_time: string})[]> {
        const params = new URLSearchParams({
            duration: duration.toISO(),
            bay_size,
            default_garage: default_garage.toString(),
            aepsatellite: aepsatellite.toString(),
        });
        return await this.fetch(`${AppConfig.API_BASE_URL}/garage/changegarage?${params}`);
    }

    /**
     * Get appointment by id
     * @param id
     */
    async getAppointment(id: number): Promise<Appointment> {
        const params = new URLSearchParams({id: id.toString()});
        return await this.fetch(`${AppConfig.API_BASE_URL}/appointment?${params}`);
    }

    async getRelatedAppointment(id: number): Promise<Appointment[]> {
        const params = new URLSearchParams({id: id.toString()});
        return await this.fetch(`${AppConfig.API_BASE_URL}/appointment/relatedAppt?${params}`);
    }

    /**
     * Get appointments for current user, optionally filtered by status
     * @param status: status code to filter by
     */
    async getMyAppointments(status?: ApptStatusCode): Promise<Appointment[]> {
        const params = new URLSearchParams();
        status && params.append('status', status);
        return await this.fetch(`${AppConfig.API_BASE_URL}/appointment/me?${params}`);
    }

    /**
     * Get my crew's appointments, optionally filtered by status.
     * Here 'crew appointments' are any associated with vehicles we are scheduler for, or users we supervise
     * @param status: status code to filter by
     */
    async getCrewAppointments(status?: ApptStatusCode): Promise<Appointment[]> {
        const params = new URLSearchParams();
        status && params.append('status', status);
        return await this.fetch(`${AppConfig.API_BASE_URL}/appointment/crew?${params}`);
    }

    /** Search vehicles by id, search query, & associated red flag/operator assignment
     * @param ids: Ids of the operators
     *        searchQuery: Search query
     * @return: Array of vehicle objects
     * */
    async searchMyAppointments(searchQuery: string, status?: ApptStatusCode): Promise<Appointment[]> {
        const params = new URLSearchParams();
        params.append('search', searchQuery);
        status && params.append('status', status);
        return await this.fetch(`${AppConfig.API_BASE_URL}/appointment/searchMe/?${params}`);
    }

    /** Search vehicles by id, search query, & associated red flag/operator assignment
     * @param ids: Ids of the operators
     *        searchQuery: Search query
     * @return: Array of vehicle objects
     * */
    async searchCrewAppointments(searchQuery: string, status?: ApptStatusCode): Promise<Appointment[]> {
        const params = new URLSearchParams();
        params.append('search', searchQuery);
        status && params.append('status', status);
        return await this.fetch(`${AppConfig.API_BASE_URL}/appointment/searchCrew/?${params}`);
    }

    /**
     * Get completed/missed appointments for a specific vehicle
     * @param id: vehicle ID
     * @param ascending: If set, sorts by increasing update_time, otherwise sorts by most recent update_time first
     */
    async getVehicleAppointments(id: number, ascending?: boolean) {
        const params = new URLSearchParams({id: id.toString()});
        ascending && params.append('ascending', 'yes');
        return await this.fetch(`${AppConfig.API_BASE_URL}/appointment/vehicle?${params}`);
    }

    /**
     * Get appointment timeslots for an appointment of `duration` at `garage` on `date
     * @return: List of potential {bays, start_time} timeslots for appointment
     * @param duration
     * @param bay_size
     * @param garage
     * @param start_date
     * @param aepsatellite
     */
    async getNextAvailableDay(
        duration: Duration,
        bay_size: BaySize,
        garage: number,
        start_date: DateTime,
        aepsatellite: number
    ): Promise<string> {
        const params = new URLSearchParams({
            duration: duration.toString(),
            bay_size: bay_size,
            garage: garage.toString(),
            date: start_date.toISODate(),
            aepsatellite: aepsatellite.toString(),
        });
        return await this.fetch(`${AppConfig.API_BASE_URL}/garage/next-available?${params}`);
    }

    /**
     * Get appointment timeslots for an appointment of `duration` at `garage` on `date
     * @param duration: Duration of desired appointment
     * @param bay_size: Required vehicle bay size
     * @param garage: Garage to schedule appointment at
     * @param date: Date to try scheduling on. Only date part is used
     * @param aepsatellite
     * @return: List of potential {bays, start_time} timeslots for appointment
     */
    async getAppointmentTimeslots(
        duration: Duration,
        bay_size: BaySize,
        garage: number,
        date: DateTime,
        aepsatellite: number
    ): Promise<{bay_id: GarageBay['id']; start_time: string; end_time: string}[]> {
        const params = new URLSearchParams({
            duration: duration.toISO(),
            bay_size,
            garage: garage.toString(),
            date: date.toISODate(),
            aepsatellite: aepsatellite.toString(),
        });
        return await this.fetch(`${AppConfig.API_BASE_URL}/garage/schedule?${params}`);
    }

    /**
     * Get appointment timeslots for an appointment of `duration` for a specific satellite garage on `date
     * @param duration: Duration of desired appointment
     * @param bay_size: Required vehicle bay size
     * @param garage: Garage to schedule appointment at
     * @param bay
     * @param date: Date to try scheduling on. Only date part is used
     * @param aepsatellite
     * @return: List of potential {bays, start_time} timeslots for appointment
     */
    async getSatelliteTimeslots(
        duration: Duration,
        bay_size: BaySize,
        garage: number,
        bay: number,
        date: DateTime,
        aepsatellite: number
    ): Promise<{bay_id: GarageBay['id']; start_time: string; end_time: string}[]> {
        const params = new URLSearchParams({
            duration: duration.toISO(),
            bay_size,
            garage: garage.toString(),
            bay: bay.toString(),
            date: date.toISODate(),
        });
        return await this.fetch(`${AppConfig.API_BASE_URL}/garage/schedule-satellite?${params}`);
    }

    /**
     * Try to schedule an appointment at a specific time and bay.
     * May return a conflict error if another appointment was scheduled in the same slot.
     * @param bay: Garage bay id
     * @param appts: List of Appointment IDs
     * @param start_time: ISO-8601 DateTime of when the appointment should start
     * @param end_time
     */
    async scheduleAppointment(
        bay: number,
        appts: number[],
        start_time: DateTime,
        end_time: DateTime
    ): Promise<GarageBayScheduledWork[]> {
        return await this.fetch(`${AppConfig.API_BASE_URL}/garage/schedule`, {
            headers: {'Content-type': 'application/json'},
            method: 'POST',
            body: JSON.stringify({
                bay,
                appts,
                start_time: start_time.toUTC().toISO(),
                end_time: end_time.toUTC().toISO(),
            }),
        });
    }

    /**
     * Get user information
     * @param id: id of user
     * @return: The full user object including corresponding default garage, notification preference objects
     */
    async getUser(id: number): Promise<User> {
        const params = new URLSearchParams({id: id.toString()});
        return await this.fetch(`${AppConfig.API_BASE_URL}/user/?${params}`);
    }

    /**
     * Get the currently signed-in user
     * In the future this will take no parameters and will simply pass through the JWT
     * @param id
     * @param [jwt]: Optional JWT token to provide in the Authorization header
     */
    async getSignedInUser(id?: number, jwt?: string): Promise<User> {
        const params = new URLSearchParams(id ? {id: id.toString()} : undefined);
        return await this.fetch(
            `${AppConfig.API_BASE_URL}/user/me/?${params}`,
            jwt
                ? {
                      headers: {Authorization: 'Bearer ' + jwt},
                  }
                : undefined
        );
    }

    /**
     * Get list of operators managed by us, a supervisor
     * @return: List of operation user objects
     */
    async getOperators(): Promise<Array<User>> {
        return await this.fetch(`${AppConfig.API_BASE_URL}/user/operators?`);
    }

    /**
     * Update user profile settings
     * @body_param user: User attributes to be updated
     * @return: Updated user object
     */
    async updateUserProfile(user: OptionalExcept<User, 'id'>): Promise<User> {
        return await this.fetch(`${AppConfig.API_BASE_URL}/user`, {
            headers: {'Content-type': 'application/json'},
            method: 'PATCH',
            body: JSON.stringify(user),
        });
    }
    /**
     * Update user notification preference settings
     * @body_param pref: Notification preference object to update to
     * @return: Updated notification preference object
     */
    async updateUserNotificationSetting(pref: UserNotificationPref): Promise<UserNotificationPref> {
        const params = new URLSearchParams({user_id: pref.user_id.toString()});
        return await this.fetch(`${AppConfig.API_BASE_URL}/user/user-notification-pref/?${params}`, {
            headers: {'Content-type': 'application/json'},
            method: 'PATCH',
            body: JSON.stringify(pref),
        });
    }

    /** Get red flag track history by id
     * @param id: Id of the vehicle
     * @return: Red flag track object
     * */
    async getRedFlagTracks(id: number): Promise<RedFlagTrack[]> {
        const params = new URLSearchParams({id: id.toString()});
        return await this.fetch(`${AppConfig.API_BASE_URL}/vehicle/red-flag-tracks/?${params}`);
    }

    /** Get vehicle operator assignment history by id
     * @param id: Id of the vehicle
     * @return: Vehicle operator assignment object
     * */
    async getOperatorAssignments(id: number): Promise<VehicleOperatorAssignment[]> {
        const params = new URLSearchParams({id: id.toString()});
        return await this.fetch(`${AppConfig.API_BASE_URL}/vehicle/operator-assignments/?${params}`);
    }

    /** Get vehicle by id & associated red flag/operator assignment
     * @param id: Id of the operator
     * @return: Vehicle object
     * */
    async getVehicle(id: number): Promise<Vehicle> {
        const params = new URLSearchParams({id: id.toString()});
        return await this.fetch(`${AppConfig.API_BASE_URL}/vehicle/?${params}`);
    }

    /** Get vehicle's default garage description
     * @param id: Id of the operator
     * @return: The vehicle's default garage description
     * */
    async getDefaultGarage(id: number): Promise<string> {
        const params = new URLSearchParams({id: id.toString()});
        return await this.fetch(`${AppConfig.API_BASE_URL}/vehicle/defaultgarage/?${params}`);
    }

    /** Get vehicles by id & associated red flag/operator assignment
     * @param ids: Ids of the operators
     * @return: Array of vehicle objects
     * */
    async getVehicles(ids: number[]): Promise<Vehicle[]> {
        const params = new URLSearchParams();
        ids.forEach((id: number) => params.append('id', id.toString()));
        return await this.fetch(`${AppConfig.API_BASE_URL}/vehicle/vehicles/?${params}`);
    }

    /** Search vehicles by id, search query, & associated red flag/operator assignment
     * @param ids: Ids of the operators
     *        searchQuery: Search query
     * @return: Array of vehicle objects
     * */
    async searchVehicles(ids: number[], searchQuery: string): Promise<Vehicle[]> {
        const params = new URLSearchParams();
        ids.forEach((id: number) => params.append('id', id.toString()));
        params.append('search', searchQuery);
        return await this.fetch(`${AppConfig.API_BASE_URL}/vehicle/search/?${params}`);
    }

    /** Search vehicles by id, search query, & associated red supervisor/scheduler assignment
     * @param ids: Ids of the supervisors
     *        searchQuery: Search query
     * @return: Array of vehicle objects
     * */
    async searchSupervisor(ids: number[], searchQuery: string): Promise<Vehicle[]> {
        const params = new URLSearchParams();
        ids.forEach((id: number) => params.append('id', id.toString()));
        params.append('search', searchQuery);
        return await this.fetch(`${AppConfig.API_BASE_URL}/vehicle/searchSupervisor/?${params}`);
    }

    /**
     * Create a new vehicle operator assignment record and update vehicle ownership
     * @param operatorAssignmentOmit: Assignment information to insert
     * @return: Updated vehicle object
     */
    async reportOwnership(operatorAssignmentOmit: VehicleOperatorAssignmentOmit): Promise<VehicleOperatorAssignment> {
        return await this.fetch(`${AppConfig.API_BASE_URL}/vehicle/report-ownership`, {
            headers: {'Content-type': 'application/json'},
            method: 'POST',
            body: JSON.stringify(operatorAssignmentOmit),
        });
    }

    /**
     * Create a new vehicle operator assignment record and update vehicle ownership
     * @param operatorAssignmentOmit: Assignment information to insert
     * @return: Updated vehicle object
     */
    async updateOwnership(
        operatorAssignmentOmit: VehicleOperatorAssignmentCombine
    ): Promise<VehicleOperatorAssignment> {
        return await this.fetch(`${AppConfig.API_BASE_URL}/vehicle/update-ownership`, {
            headers: {'Content-type': 'application/json'},
            method: 'POST',
            body: JSON.stringify(operatorAssignmentOmit),
        });
    }

    /**
     * Create a new vehicle red flag record
     * @param redFlagTrackOmit: Red flag track object with the fields id and update_time omitted
     * @return: Updated red flag track object
     */
    async updateRedFlag(redFlagTrackOmit: RedFlagTrackOmit): Promise<RedFlagTrack> {
        return await this.fetch(`${AppConfig.API_BASE_URL}/vehicle/update-red-flag`, {
            headers: {'Content-type': 'application/json'},
            method: 'POST',
            body: JSON.stringify(redFlagTrackOmit),
        });
    }

    async updateVendorWork(vendorWorkOmit: OptionalIfNull<VendorWorkOmit>): Promise<VendorWorkOmit> {
        return await this.fetch(`${AppConfig.API_BASE_URL}/appointment/update-vendor-work`, {
            headers: {'Content-type': 'application/json'},
            method: 'POST',
            body: JSON.stringify(vendorWorkOmit),
        });
    }

    async updateApptWork(apptWorkOmits: OptionalIfNull<ApptWorkOmit>[]): Promise<ApptWork> {
        return await this.fetch(`${AppConfig.API_BASE_URL}/appointment/update-appt-work`, {
            headers: {'Content-type': 'application/json'},
            method: 'POST',
            body: JSON.stringify(apptWorkOmits),
        });
    }

    async getApptWorkClass(): Promise<Classification[]> {
        return await this.fetch(`${AppConfig.API_BASE_URL}/appointment/appt-work-class/`);
    }

    async cancelAppointment(id: number): Promise<ApptWorkOmit> {
        const params = new URLSearchParams();
        params.append('id', id.toString());
        return await this.fetch(`${AppConfig.API_BASE_URL}/appointment/cancel-appt?${params}`, {
            headers: {},
            method: 'POST',
        });
    }
}
