// DateTimePicker.web.ts
import React, {useEffect, useRef, ReactElement} from 'react';
import {createElement, StyleSheet, TextInput, TextInputProps, ViewStyle} from 'react-native';
import {DateTime} from 'luxon';
import {useTheme} from 'react-native-paper';

type modeType = 'date' | 'datetime-local';
interface Props {
    value: string;
    min?: string;
    max?: string;
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    mode?: modeType;
    style?: ViewStyle;
    // ...more
}
export default function DatePicker({value, min, max, onChange, mode, style}: Props) {
    const {fonts} = useTheme();
    const styles = StyleSheet.create({
        datePicker: {
            borderWidth: 0,
            fontSize: 18,
            fontFamily: fonts.regular.fontFamily,
            ...style,
        },
    });
    return (
        <>
            {createElement('input', {
                type: mode ? mode : 'date',
                className: 'date-picker',
                value: value,
                min: min,
                max: max,
                onChange: onChange,
                style: styles.datePicker,
                id: 'someId',
            })}
        </>
    );
}
