import {Font} from 'react-native-paper/lib/typescript/types';

declare global {
    // Using namespace to set properties globally
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace ReactNativePaper {
        interface ThemeColors {
            warn: string;
            completed: string;
            upcoming: string;
        }
        interface Fonts {
            bold: Font;
        }
    }
}
// import { DefaultTheme } from 'react-native-paper';
//
// export const SCREEN_PADDING = 8;
// export const BUTTON_WIDTH = 60;

import {configureFonts, DefaultTheme} from 'react-native-paper';
import {DefaultTheme as NavigationDefaultTheme} from '@react-navigation/native';
import styled from 'styled-components/native';

const fontConfig = {
    web: {
        regular: {
            fontFamily: 'sans-serif',
            fontWeight: '500',
        } as Font,
        medium: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
        } as Font,
        light: {
            fontFamily: 'sans-serif',
            fontWeight: '300',
        } as Font,
        thin: {
            fontFamily: 'sans-serif',
            fontWeight: '200',
        } as Font,
        bold: {
            fontFamily: 'sans-serif',
            fontWeight: '700',
        } as Font,
    },
    ios: {
        regular: {
            fontFamily: 'San Francisco',
            fontWeight: '500',
        } as Font,
        medium: {
            fontFamily: 'San Francisco',
            fontWeight: '400',
        } as Font,
        light: {
            fontFamily: 'San Francisco',
            fontWeight: '300',
        } as Font,
        thin: {
            fontFamily: 'San Francisco',
            fontWeight: '200',
        } as Font,
        bold: {
            fontFamily: 'San Francisco',
            fontWeight: '700',
        } as Font,
    },
    // android: {
    //     regular: {
    //         fontFamily: 'sans-serif-semibold',
    //         fontWeight: 'normal',
    //     } as Font,
    //     medium: {
    //         fontFamily: 'sans-serif-bold',
    //         fontWeight: 'normal',
    //     } as Font,
    //     light: {
    //         fontFamily: 'sans-serif-regular',
    //         fontWeight: 'normal',
    //     } as Font,
    //     thin: {
    //         fontFamily: 'sans-serif-light',
    //         fontWeight: 'normal',
    //     } as Font,
    //     bold: {
    //         fontFamily: 'sans-serif-extrabold',
    //         fontWeight: 'normal',
    //     } as Font,
    // },
};

export const aepLightTheme = {
    ...DefaultTheme,
    ...NavigationDefaultTheme,
    fonts: configureFonts(fontConfig),
    roundness: 10,
    colors: {
        ...DefaultTheme.colors,
        ...NavigationDefaultTheme.colors,
        text: '#333',
        primary: '#548F96',
        primary_lighter: '#f0f1f7',
        accent: '#de2828',
        accent_lighter: '#ffddd4',
        warn: '#ea6a47',
        inprogress: '#8EC850',
        completed: '#3C6C95',
        upcoming: '#0e8fb5',
        secondary: '#FFFFFF',
        secondaryText: '#7A7A7A',
        tertiary: '#E8E8E8',
        divider: '#e4e4e4',
    },
};

export function getStatusColor(status: string) {
    switch (status.toLowerCase()) {
        default: {
            return 'grey';
        }
        case 'new': {
            return aepLightTheme.colors.warn;
        }
        case 'unscheduled': {
            return aepLightTheme.colors.warn;
        }
        case 'confirmed': {
            return aepLightTheme.colors.upcoming;
        }
        case 'inprogress': {
            return aepLightTheme.colors.inprogress;
        }
        case 'completed': {
            return aepLightTheme.colors.completed;
        }
    }
}

export const SectionWrapper = styled.View<{noPadding?: boolean; horizontalMargin?: boolean; radius?: boolean}>`
    padding: ${({noPadding}) => (noPadding ? 0 : 16)}px;
    margin: 8px 0 0 0;
    margin-left: ${({horizontalMargin}) => (horizontalMargin ? 8 : 0)}px;
    margin-right: ${({horizontalMargin}) => (horizontalMargin ? 8 : 0)}px;
    border-radius: ${({radius}) => (radius ? '5px' : '0')};
    background-color: #fff;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.08);
    gap: 16px;
`;
export const Row = styled.View<{alignment?: 'flex-start' | 'center'; justify?: 'space-between' | 'start'}>`
    flex-wrap: wrap;
    flex-direction: row;
    align-items: ${({alignment}) => (alignment ? alignment : 'flex-start')};
    justify-content: ${({justify}) => (justify ? justify : 'space-between')};
    margin-bottom: 4px;
`;
// Column numbers out of 12 in a Row
export const Column = styled.View<{size: number}>`
    width: ${({size}) => 100 * (size / 12)}%;
    padding: 8px 4px;
`;
export const BottomBar = styled.View`
    flex-direction: row;
    padding: 16px;
    background-color: #fff;
    box-shadow: 0px -4px 9px rgba(193, 193, 193, 0.2);
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
`;
export const PageTitle = styled.Text<{noPadding?: boolean}>`
    font-weight: 800;
    font-size: 24px;
    color: #333;
    padding: ${({noPadding}) => (noPadding ? 0 : 16)}px;
`;
export const PageContent = styled.View<{paddingVertical?: boolean}>`
    flex: 1;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: ${({paddingVertical}) => (paddingVertical ? 16 : 0)}px;
    padding-bottom: ${({paddingVertical}) => (paddingVertical ? 16 : 0)}px;
    gap: 16px;
    background-color: white;
    margin-bottom: 72px;
    overflow-y: scroll;
`;
export function getInitials(fullName: string | undefined) {
    if (!fullName || !fullName.trim()) return '';

    fullName = fullName.replace(/ *\([^)]*\) */g, '');
    return fullName
        .split(' ')
        .map((n) => n[0])
        .join('');
}
export const WarningTxt = styled.Text`
    color: ${aepLightTheme.colors.accent};
    font-style: italic;
    font-weight: bold;
    font-size: 20px;
`;
