import React, {useContext, useEffect, useState} from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {
    Avatar,
    Checkbox,
    Divider,
    IconButton,
    List,
    RadioButton,
    Snackbar,
    Text,
    useTheme,
    TextInput,
    Button,
    Dialog,
    Subheading,
    Portal,
} from 'react-native-paper';
import {StackScreenProps} from '@react-navigation/stack';
import {SupervisorPrefType, User, UserNotificationPref, UserRole} from 'shared/auth.model';
import {APIService} from 'api';
import {AuthContext, SignOutContext} from 'shared/context';

export function Settings({navigation}: StackScreenProps<any>) {
    const logout = useContext(SignOutContext);
    const insets = useSafeAreaInsets();
    const {colors} = useTheme();
    const api = new APIService();

    const {user, updateUser} = useContext(AuthContext);
    const [editDlgVisible, setEditDlgVisible] = useState(false);
    const [userProfile, setUserProfile] = useState({
        phone: '',
        email: '',
    });
    const [notifyMethod, setNotifyMethod] = useState<UserNotificationPref>({
        id: -1,
        email: true,
        text: true,
        supervisor_pref: 'include_all' as SupervisorPrefType,
        user_id: -1,
    });
    const [supervisorPref, setSupervisorPref] = useState('Include both me and my crew');
    const [searchRadius, setSearchRadius] = useState(25);
    const [snackbarVisible, setSnackbarVisible] = useState(false);

    useEffect(() => {
        if (user) {
            api.getUser(user.id)
                .then((val) => {
                    setUserProfile({
                        phone: val.cellphone_number ? val.cellphone_number : '',
                        email: val.email ? val.email : '',
                    });
                    setNotifyMethod(val.user_notification_pref);
                    val.user_role === UserRole.SUPERVISOR &&
                        val.user_notification_pref.supervisor_pref &&
                        setSupervisorPref(val.user_notification_pref?.supervisor_pref);
                })
                .catch((error) => console.log(error));
            if (user.garage_search_radius) setSearchRadius(user.garage_search_radius);
        }

        // No dependency required
        // eslint-disable-next-line
    }, [user]);

    return (
        <View style={{flex: 1, paddingTop: insets.top, paddingBottom: 16}}>
            {user && (
                <>
                    <View
                        style={{
                            flexGrow: 0,
                            flexBasis: 'auto',
                            flexShrink: 0,
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingVertical: 16,
                        }}
                    >
                        <Avatar.Text
                            size={120}
                            label={user ? user.first_name[0] + user.last_name[0] : ''}
                            style={{borderWidth: 5, borderColor: '#c0d4d6'}}
                            labelStyle={{lineHeight: 80, fontSize: 60}}
                        />
                        <Text>Hello, {user.first_name + ' ' + user.last_name}</Text>
                        <Text>{user.user_role.toUpperCase()}</Text>
                    </View>

                    <List.Section>
                        <List.Subheader>My Profile</List.Subheader>
                        <List.Item
                            title='Cell phone number'
                            left={() => <List.Icon icon='phone' />}
                            right={() => (
                                <IconButton
                                    icon='pencil-outline'
                                    color={colors.placeholder}
                                    onPress={() => setEditDlgVisible(true)}
                                />
                            )}
                            description={user.cellphone_number ? user.cellphone_number : ''}
                            style={styles.whiteBg}
                        />
                        <Divider />
                        <List.Item
                            title='Email'
                            description={user.email ? user.email : ''}
                            left={() => <List.Icon icon='email' />}
                            // Email address is linked to SSO authentication, so should not be editable by user
                            // right={() => (
                            //     <IconButton
                            //         icon='pencil-outline'
                            //         color={colors.placeholder}
                            //         onPress={() => setEditDlgVisible(true)}
                            //     />
                            // )}
                            style={styles.whiteBg}
                        />
                        <Divider />
                        <List.Accordion
                            title='Garage Search Radius'
                            description={searchRadius}
                            left={() => <List.Icon icon='garage' />}
                            style={styles.whiteBg}
                        >
                            <RadioButton.Group
                                onValueChange={(val) => {
                                    setSearchRadius(parseInt(val));
                                    api.updateUserProfile({
                                        garage_search_radius: parseInt(val),
                                        id: user.id,
                                    }).then((val) => {
                                        updateUser(val);
                                        setSnackbarVisible(true);
                                    });
                                }}
                                value={searchRadius.toString()}
                            >
                                <RadioButton.Item label='25 Miles' value='25' color={colors.primary} />
                                <RadioButton.Item label='50 Miles' value='50' color={colors.primary} />
                                <RadioButton.Item label='100 Miles' value='100' color={colors.primary} />
                            </RadioButton.Group>
                        </List.Accordion>
                        <Divider />
                    </List.Section>

                    {notifyMethod.id !== -1 && (
                        <List.AccordionGroup>
                            <List.Subheader>Notification Preferences</List.Subheader>
                            <List.Accordion
                                id={1}
                                title='Notification'
                                description={
                                    (notifyMethod.email ? 'Email, ' : '') + (notifyMethod.text ? 'Text message' : '')
                                }
                                left={(props) => <List.Icon {...props} icon='message-alert-outline' />}
                                style={{backgroundColor: 'white'}}
                            >
                                <Checkbox.Item
                                    theme={{colors: {accent: colors.primary}}}
                                    label='Email'
                                    status={notifyMethod.email ? 'checked' : 'unchecked'}
                                    onPress={() => {
                                        const updatedPref = {
                                            ...notifyMethod,
                                            email: !notifyMethod.email,
                                        };
                                        setNotifyMethod(updatedPref);
                                        api.updateUserNotificationSetting(updatedPref).then(() => {
                                            setSnackbarVisible(true);
                                        });
                                    }}
                                />
                                <Checkbox.Item
                                    theme={{colors: {accent: colors.primary}}}
                                    label='Text Message'
                                    status={notifyMethod.text ? 'checked' : 'unchecked'}
                                    onPress={() => {
                                        const updatedPref = {
                                            ...notifyMethod,
                                            text: !notifyMethod.text,
                                        };
                                        setNotifyMethod(updatedPref);
                                        api.updateUserNotificationSetting(updatedPref).then(() => {
                                            setSnackbarVisible(true);
                                        });
                                    }}
                                />
                            </List.Accordion>
                            <Divider />
                            {user.user_role === UserRole.SUPERVISOR && notifyMethod.id !== -1 && (
                                <List.Accordion
                                    id={2}
                                    title='Supervisor Preference'
                                    description={supervisorPref}
                                    left={(props) => <List.Icon {...props} icon='message-settings' />}
                                    style={{backgroundColor: 'white'}}
                                >
                                    <RadioButton.Group
                                        onValueChange={(val) => {
                                            setSupervisorPref(val);
                                            const updatedPref = {
                                                ...notifyMethod,
                                                supervisor_pref: val as SupervisorPrefType,
                                            };
                                            setNotifyMethod(updatedPref);
                                            api.updateUserNotificationSetting(updatedPref).then(() => {
                                                setSnackbarVisible(true);
                                            });
                                        }}
                                        value={supervisorPref}
                                    >
                                        <RadioButton.Item
                                            label='Receive notifications about me and my crew'
                                            value='include all'
                                            color={colors.primary}
                                        />
                                        <RadioButton.Item
                                            label='Receive notifications about my crew'
                                            value='exclude me'
                                            color={colors.primary}
                                        />
                                        <RadioButton.Item
                                            label='Receive notifications about my activities only'
                                            value='include me only'
                                            color={colors.primary}
                                        />
                                    </RadioButton.Group>
                                </List.Accordion>
                            )}
                        </List.AccordionGroup>
                    )}
                    <Portal>
                        <Dialog visible={editDlgVisible} onDismiss={() => setEditDlgVisible(false)}>
                            <Dialog.Title>Edit Profile</Dialog.Title>
                            <Dialog.Content>
                                <Subheading style={{fontWeight: 'bold', marginTop: 16}}>Cellphone number</Subheading>
                                <TextInput
                                    mode='outlined'
                                    label={'Best number to reach you'}
                                    value={userProfile.phone}
                                    keyboardType='numeric'
                                    onChangeText={(text) => {
                                        if (text.length <= 1) text = '+1'; // add on US country code
                                        setUserProfile((prevState) => {
                                            return {
                                                ...prevState,
                                                phone: text,
                                            };
                                        });
                                    }}
                                />
                                {/*<Subheading style={{fontWeight: 'bold', marginTop: 16}}>Email address</Subheading>*/}
                                {/*<TextInput*/}
                                {/*    mode='outlined'*/}
                                {/*    label={'Email address'}*/}
                                {/*    value={userProfile.email}*/}
                                {/*    onChangeText={(text) =>*/}
                                {/*        setUserProfile((prevState) => {*/}
                                {/*            return {*/}
                                {/*                ...prevState,*/}
                                {/*                email: text,*/}
                                {/*            };*/}
                                {/*        })*/}
                                {/*    }*/}
                                {/*/>*/}
                            </Dialog.Content>
                            <Dialog.Actions>
                                <Button
                                    onPress={() => {
                                        api.updateUserProfile({
                                            email: userProfile.email,
                                            cellphone_number: userProfile.phone,
                                            id: user.id,
                                        }).then((val) => {
                                            updateUser(val);
                                            setEditDlgVisible(false);
                                            setSnackbarVisible(true);
                                        });
                                    }}
                                >
                                    Save
                                </Button>
                            </Dialog.Actions>
                        </Dialog>
                    </Portal>
                </>
            )}

            <List.Section>
                <TouchableOpacity onPress={() => logout && logout()}>
                    <List.Item
                        title='Sign Out'
                        left={() => <List.Icon icon='logout' color={colors.accent} />}
                        style={styles.whiteBg}
                        titleStyle={{color: colors.accent}}
                    />
                </TouchableOpacity>
                <Divider />
            </List.Section>

            <Snackbar visible={snackbarVisible} onDismiss={() => setSnackbarVisible(false)} duration={1000}>
                Settings updated!
            </Snackbar>
        </View>
    );
}

const styles = StyleSheet.create({
    whiteBg: {
        backgroundColor: 'white',
    },
});
