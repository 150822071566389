// Note that the search function passed here should not change with each render, or it will create an infinite update loop
import React, {useEffect, useState} from 'react';
import {TextInput as PaperTextInput} from 'react-native-paper';
import {aepLightTheme} from 'theme';
import {StyleSheet} from 'react-native';

export default function SearchBar({onSearch}: {onSearch: (query: string) => void}) {
    const [value, setValue] = useState('');
    useEffect(() => {
        // If query has been cleared, signal to enclosing components
        if (!value) onSearch(value);
    }, [onSearch, value]);
    return (
        <PaperTextInput
            mode='outlined'
            value={value}
            placeholder='Search'
            theme={aepLightTheme}
            style={requestlistStyles.searchbar}
            onChangeText={setValue}
            right={
                <PaperTextInput.Icon
                    theme={aepLightTheme}
                    name='close-circle'
                    onPress={() => setValue('')}
                    color={aepLightTheme.colors.secondaryText}
                />
            }
            left={
                <PaperTextInput.Icon
                    theme={aepLightTheme}
                    name='magnify'
                    onPress={() => onSearch(value)}
                    color={aepLightTheme.colors.primary}
                />
            }
            onSubmitEditing={() => onSearch(value)}
        />
    );
}

const requestlistStyles = StyleSheet.create({
    searchbar: {
        width: '100%',
        paddingHorizontal: 24,
        backgroundColor: '#fff',
    },
});
