import {Appointment} from 'shared/appt.model';
import React from 'react';
import {TouchableOpacity, View, StyleSheet} from 'react-native';
import {Text} from 'react-native-paper';
import {getStatusColor} from 'theme';
import {DateTime, Duration} from 'luxon';

export type ApptDetailCardProps = {
    appt: Appointment;
    gotoDetails?: (app: Appointment) => any;
    contentStyle?: any;
};

const ApptDetailCard: React.FC<ApptDetailCardProps> = (props) => {
    const {appt, gotoDetails, contentStyle} = props;
    const cardStyles = StyleSheet.create({
        card: {
            borderLeftColor: 'gray',
            borderLeftWidth: 6,
            flexGrowth: 0,
            flexBasis: 'auto',
            flexShrink: 0,
            backgroundColor: 'white',
            borderRadius: 4,
            marginHorizontal: 4,
            marginVertical: 4,
            paddingHorizontal: 16,
            paddingVertical: 16,
            gap: 4,
            shadowColor: '#000',
            shadowOffset: {
                width: 0,
                height: 1,
            },
            shadowOpacity: 0.25,
            shadowRadius: 5,
            elevation: 1,
            minWidth: 350,
            flexDirection: 'column',
        },
        label: {
            fontSize: 14,
            color: '#3333338a',
        },
        value: {
            fontSize: 14,
            fontWeight: 'bold',
        },
        statusLabel: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
        },
        textView: {
            flexDirection: 'column',
            marginTop: 5,
            paddingRight: 20,
        },
    });
    let status = appt.status ? appt.status[0].status_code : 'NONE';
    const statusTime = appt?.status?.[0].update_time;
    const desc =
        appt.work_type === 'PM'
            ? 'Preventative Maintenance Needed'
            : appt.work_type === 'CMPWO'
            ? 'Recall is Required'
            : 'Maintenance Required';

    if (status === 'VCOMP') status = 'COMPLETED';
    return (
        <TouchableOpacity
            style={[cardStyles.card, {borderLeftColor: getStatusColor(status)}, contentStyle]}
            onPress={() => {
                if (gotoDetails) gotoDetails(appt);
            }}
            delayPressIn={100}
        >
            <View style={cardStyles.statusLabel}>
                <Text style={cardStyles.label}> {appt.wonum ? '#' + appt.wonum : 'N/A'}</Text>
                <Text style={{fontWeight: 'bold', color: getStatusColor(status)}}>{status}</Text>
                {(status === 'COMPLETED' || status === 'VCOMP') && statusTime && (
                    <Text> - {new Date(statusTime).toDateString()}</Text>
                )}
            </View>

            <Text style={{fontSize: 16, fontWeight: 'bold'}}>{desc}</Text>

            <View style={{flexDirection: 'row'}}>
                <View style={cardStyles.textView}>
                    <Text style={cardStyles.label}>{(appt.work_type === 'PM' ? 'PM ' : '') + 'Due Date'}</Text>
                    <Text style={cardStyles.value}>
                        {appt.due_date
                            ? DateTime.fromISO(appt.due_date).toUTC().toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
                            : 'N/A'}
                    </Text>
                </View>

                <View style={cardStyles.textView}>
                    <Text style={cardStyles.label}>Expected Duration</Text>
                    <Text style={cardStyles.value}>
                        {appt.duration ? Duration.fromISO(appt.duration).toHuman() : 'N/A'}
                    </Text>
                </View>
            </View>

            <View style={cardStyles.textView}>
                <Text style={cardStyles.label}>Repair Code</Text>
                <Text style={cardStyles.value}>
                    {(appt.repair_code ? appt.repair_code : 'Missing Repair Code') +
                        ': ' +
                        (appt.task_description ? appt.task_description : 'Missing Task Description')}
                </Text>
            </View>
        </TouchableOpacity>
    );
};

export default ApptDetailCard;
