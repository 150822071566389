import {Garage} from 'shared/garage.model';

export enum UserRole {
    OPERATOR = 'operator',
    SUPERVISOR = 'supervisor',
}
export interface User {
    id: number;
    first_name: string;
    last_name: string;
    email: string | null;
    cellphone_number: string | null;
    user_role: UserRole;
    default_garage_id: number;
    garage_search_radius: number | null;
    supervisor_id: number | null;
    user_notification_pref: UserNotificationPref;
    default_garage: Garage | null;
	department: string | null;
}

export type SupervisorPrefType = 'include all' | 'exclude me' | 'include me only';

export interface UserNotificationPref {
    id: number;
    email: boolean;
    text: boolean;
    supervisor_pref: SupervisorPrefType;
    user_id: number;
}
