import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import {useIsFocused, useNavigation} from '@react-navigation/native';
import {StackScreenProps} from '@react-navigation/stack';
import {APIService} from 'api';
import FABMenu from 'components/FABMenu';
import TopBar from 'components/TopBar';
import {StackParamList} from 'linking';
import {DateTime} from 'luxon';
import React, {useContext, useEffect, useState} from 'react';
import {View} from 'react-native';
import {
    Button,
    Dialog,
    Divider,
    List,
    Portal,
    RadioButton,
    Subheading,
    Text,
    TextInput,
    useTheme,
} from 'react-native-paper';
import {Vehicle, flags, Flags} from 'shared/appt.model';
import {User, UserRole} from 'shared/auth.model';
import {AuthContext} from 'shared/context';
import {PageContent, PageTitle, Row} from 'theme';

export default function VehicleDetails({route}: StackScreenProps<StackParamList, 'VehicleDetails'>) {
    const {colors} = useTheme();
    const navigation = useNavigation();
    const isFocused = useIsFocused();
    const user = useContext(AuthContext).user;
    const api = new APIService();

    const [vehicle, setVehicle] = useState<Vehicle | null>(null);
    const [description, setDescription] = useState<string | null>(null);
    const [flag, setFlag] = useState<string | null>(null);
    const [redFlagReason, setRedFlagReason] = useState<string | null>('');
    const [redFlagUpdatedBy, setRedFlagUpdatedBy] = useState<number>(user.id);
    const [redFlagDialogVisible, setRedFlagDialogVisible] = useState(false);
    const [value, setValue] = useState<any>(null);
    const [text, setText] = useState<any>('');

    const [operator, setOperator] = useState<User>({
        first_name: 'Not',
        last_name: 'Available',
        id: -1,
        user_role: UserRole.OPERATOR,
    } as User);

    const handleClick = (action: string) => {
        if (action === 'red-flag') {
            setRedFlagDialogVisible(true);
            console.log('presssssed');
        }
    };

    useEffect(() => {
        api.getVehicle(route.params.vehicleId).then((v) => {
            setVehicle(v);
            if (v.red_flag_track) {
                setFlag(v.red_flag_track[0].is_flagged);
                setRedFlagReason(v.red_flag_track[0].reason);
                setRedFlagUpdatedBy(v.red_flag_track[0].updated_by);
            }
        });
        api.getDefaultGarage(route.params.vehicleId).then((d) => {
            setDescription(d);
        });
        // Depend on vehicleId and isFocused only
        // eslint-disable-next-line
    }, [route.params.vehicleId, isFocused]);

    useEffect(() => {
        if (vehicle && vehicle.operator)
            api.getUser(vehicle.operator).then((op) => {
                setOperator(op);
            });
        // Depend on vehicle only
        // eslint-disable-next-line
    }, [vehicle]);

    return (
        vehicle && (
            <View style={{flex: 1, backgroundColor: '#fff'}}>
                <TopBar />
                <PageContent paddingVertical>
                    <Row alignment={'center'}>
                        <PageTitle noPadding>{vehicle?.description}</PageTitle>
                        <Button
                            disabled={user.id !== redFlagUpdatedBy && !!flag}
                            mode='outlined'
                            icon={'flag-outline'}
                            uppercase={false}
                            style={{
                                borderRadius: 30,
                                borderColor:
                                    user.id !== redFlagUpdatedBy && flag
                                        ? colors.disabled
                                        : flag
                                        ? colors.text
                                        : colors.accent,
                            }}
                            labelStyle={{
                                color:
                                    user.id !== redFlagUpdatedBy && flag
                                        ? colors.disabled
                                        : flag
                                        ? colors.text
                                        : colors.accent,
                            }}
                            onPress={() => {
                                setRedFlagDialogVisible(true);
                            }}
                        >
                            {flag ? 'Remove' : 'Mark'} Red Flag
                        </Button>
                    </Row>
                    {flag && (
                        <List.Item
                            title={flags[flag as Flags] + ' - Red Flag Reason'}
                            description={redFlagReason}
                            left={(props) => (
                                <MaterialCommunityIcons name='flag-outline' size={26} color={colors.accent} />
                            )}
                            style={{padding: 0}}
                        />
                    )}
                    <Divider />
                    <List.Item
                        title={operator ? operator.first_name + ' ' + operator.last_name : 'No Operator'}
                        left={(props) => (
                            <MaterialCommunityIcons name='account-outline' size={26} color={colors.primary} />
                        )}
                        right={(props) => {
                            return user.user_role === UserRole.SUPERVISOR ? (
                                <Button
                                    disabled={!!flag}
                                    mode='text'
                                    onPress={() =>
                                        navigation.navigate('ModalScreens', {
                                            screen: 'ChangeOwnership',
                                            params: {vehicleId: vehicle.id},
                                        })
                                    }
                                >
                                    Change
                                </Button>
                            ) : (
                                <></>
                            );
                        }}
                        style={{padding: 0}}
                    />
                    {vehicle.operator_assignment?.[0]?.status === 'PENDING' && (
                        <List.Item
                            title={'Memo: ' + vehicle.operator_assignment[0].memo}
                            left={(props) => (
                                <MaterialCommunityIcons name='information-outline' size={26} color={colors.accent} />
                            )}
                            right={(props) => {
                                return (
                                    vehicle.operator_assignment?.[0].memo?.slice(0, 10) === 'Department' && (
                                        <Button
                                            disabled={!!flag}
                                            mode='text'
                                            onPress={() =>
                                                api
                                                    .reportOwnership({
                                                        vehicle_id: vehicle.id,
                                                        status: 'COMPLETE',
                                                        memo:
                                                            vehicle.operator_assignment?.[0].memo ||
                                                            'Department Changed',
                                                        updated_by: user.id,
                                                        operator: operator.id,
                                                    })
                                                    .then(() => {
                                                        api.getVehicle(route.params.vehicleId).then(setVehicle);
                                                    })
                                            }
                                        >
                                            Confirm Department
                                        </Button>
                                    )
                                );
                            }}
                            style={{padding: 0}}
                            titleStyle={{color: colors.accent}}
                        />
                    )}
                    <Divider />
                    <List.Item
                        title={'Alias'}
                        description={vehicle.alias}
                        left={(props) => <MaterialCommunityIcons name='car-back' size={26} color={colors.primary} />}
                        style={{padding: 0}}
                    />
                    <Divider />
                    <List.Item
                        title={'Latest PM Work'}
                        description={
                            vehicle.latest_pm_work &&
                            vehicle.latest_pm_work.scheduled_starttime &&
                            vehicle.latest_pm_work.scheduled_endtime
                                ? DateTime.fromISO(vehicle.latest_pm_work?.scheduled_starttime).toFormat(
                                      'MMM d, yyyy, h:mma'
                                  ) +
                                  '-' +
                                  DateTime.fromISO(vehicle.latest_pm_work?.scheduled_endtime).toFormat('h:mma')
                                : 'To be scheduled'
                        }
                        left={(props) => (
                            <MaterialCommunityIcons name='calendar-blank' size={26} color={colors.primary} />
                        )}
                        right={(props) => (
                            <Button
                                mode='text'
                                onPress={() => navigation.navigate('ApptDetails', {apptId: vehicle.latest_pm_work?.id})}
                            >
                                View
                            </Button>
                        )}
                        style={{padding: 0}}
                    />
                    <Divider />
                    <List.Item
                        title={'Default Repair Facility'}
                        description={description ? description : 'No default garage'}
                        left={(props) => <MaterialIcons name='location-pin' size={26} color={colors.primary} />}
                        style={{padding: 0}}
                    />
                    <Divider />

                    <Portal>
                        <Dialog
                            visible={redFlagDialogVisible}
                            onDismiss={() => {
                                setRedFlagDialogVisible(false);
                                setText('');
                                setValue(null);
                            }}
                        >
                            <Dialog.Title>{flag ? 'Remove' : 'Mark'} Red Flag</Dialog.Title>
                            <Dialog.Content>
                                <RadioButton.Group onValueChange={(newValue) => setValue(newValue)} value={value}>
                                    {flag ? (
                                        <RadioButton.Item label='No Tag' value='NONE' color={colors.primary} />
                                    ) : (
                                        Object.keys(flags).map((f, index: number) => (
                                            <View key={index.toString()}>
                                                <RadioButton.Item
                                                    label={flags[f as Flags]}
                                                    value={index.toString()}
                                                    color={colors.primary}
                                                    mode='android'
                                                />
                                                <Divider style={{marginTop: 16}} />
                                            </View>
                                        ))
                                    )}
                                </RadioButton.Group>
                                <Subheading style={{fontWeight: 'bold', marginTop: 16}}>Reason</Subheading>
                                <TextInput
                                    mode='outlined'
                                    multiline={true}
                                    label='Describe the reason for change...'
                                    onChangeText={(text) => setText(text)}
                                    style={{height: 150}}
                                />
                            </Dialog.Content>
                            <Dialog.Actions>
                                <Button
                                    disabled={!value}
                                    onPress={() => {
                                        api.updateRedFlag({
                                            vehicle_id: vehicle.id,
                                            is_flagged: flag ? null : (Object.keys(flags)[value] as Flags),
                                            reason: text,
                                            updated_by: user.id,
                                        })
                                            .then((rft) => {
                                                setFlag(flag ? null : Object.keys(flags)[value]);
                                                setRedFlagDialogVisible(false);
                                                setRedFlagReason(text);
                                                setRedFlagUpdatedBy(rft.updated_by);
                                            })
                                            .catch((err) => {
                                                setRedFlagReason('');
                                                console.log(err);
                                            });
                                        setValue(null);
                                        setText('');
                                    }}
                                >
                                    Submit
                                </Button>
                            </Dialog.Actions>
                        </Dialog>
                    </Portal>
                </PageContent>
                <FABMenu vehicle={vehicle} handleClick={handleClick} />
            </View>
        )
    );
}
