import React, {useEffect, useRef, useState, Fragment, useContext} from 'react';
import {ScrollView, StyleSheet, View, ViewStyle} from 'react-native';
import {Divider, List, Text, Subheading, useTheme, Chip, Button, RadioButton, Caption} from 'react-native-paper';
import {aepLightTheme as theme, Row, BottomBar, PageContent} from 'theme';
import ActionButton from '@components/Button';
import {DateTime, Duration} from 'luxon';
import {useNavigation} from '@react-navigation/native';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import {ApptContext, AuthContext} from 'shared/context';
import {APIService} from 'api';
import {StackNavigationProp, StackScreenProps} from '@react-navigation/stack';
import {ThenArg} from 'types';
import {StackParamList} from 'linking';
import * as Location from 'expo-location';
import {LocationObject} from 'expo-location/src/Location.types';

export default function ChangeGarageModal({route}: StackScreenProps<any>) {
    const {colors} = useTheme();
    const navigation = useNavigation<StackNavigationProp<StackParamList>>();
    const [value, setValue] = React.useState('');
    const apptContext = useContext(ApptContext);
    const user = useContext(AuthContext).user;
    const [garages, setGarages] = useState<ThenArg<ReturnType<APIService['getAvailableGarages']>>>([]);
    const appt = apptContext.value;
    const [usrLocation, setUsrLocation] = useState<LocationObject | null>(null);

    // Request location permissions on first render
    useEffect(() => {
        (async () => {
            const {status} = await Location.requestForegroundPermissionsAsync();
            if (status !== 'granted') return;

            const location = await Location.getCurrentPositionAsync({});
            setUsrLocation(location);
        })();
    }, []);

    useEffect(() => {
        const id = parseInt(route.params?.apptId as any, 10);
        if (appt && appt.id === id) return;
        const api = new APIService();
        api.getAppointment(id)
            .then((appt) => apptContext.update(appt))
            .catch((e) => apptContext.update(null));
        // Only need value of appt, not context
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [route.params?.apptId]);

    useEffect(() => {
        if (!appt || !appt.duration) return;
        const api = new APIService();
        const lat = usrLocation?.coords?.latitude || user.default_garage?.latitude;
        const lon = usrLocation?.coords?.longitude || user.default_garage?.longitude;
        const default_garage_id = user.default_garage_id || appt.vehicle?.default_garage_id;
        if (default_garage_id)
            api.getChangeGarages(
                Duration.fromISO(appt.duration),
                appt.vehicle?.vehicle_rating as any | 'light',
                default_garage_id,
                appt.aepsatellite,
                lat,
                lon
            ).then((gs) => {
                console.log(gs);
                setGarages(gs);
            });
    }, [appt, navigation, user, usrLocation]); // Refresh garage list on each navigation & appointment change

    return (
        <PageContent paddingVertical>
            <RadioButton.Group onValueChange={(newValue) => setValue(newValue)} value={value}>
                {/*<View style={{minHeight: 'auto'}}>*/}
                {garages.map((garage, index) => (
                    <View key={index.toString()} style={{flex: 1, flexDirection: 'row', alignItems: 'center'}}>
                        <RadioButton.Android value={index.toString()} theme={{colors: {accent: colors.primary}}} />
                        <View style={{flex: 1}}>
                            <Row alignment={'center'}>
                                <Subheading style={{fontWeight: '600'}}>
                                    {garage.description ? garage.description : 'No Garage Description'}
                                </Subheading>
                                {garage.id === user.default_garage_id && (
                                    <Chip
                                        style={{marginTop: '2px', marginBottom: '2px'}}
                                        theme={{colors: {primary: colors.surface}}}
                                    >
                                        Default
                                    </Chip>
                                )}
                            </Row>
                            {garage.distance && (
                                <List.Item
                                    title={`${Math.round(garage.distance * 10) / 10} miles (approximate)`}
                                    left={(props) => <MaterialIcons name='near-me' size={20} color={colors.primary} />}
                                    style={{padding: 0}}
                                    titleStyle={{color: colors.primary}}
                                />
                            )}
                            <Text>{garage.address}</Text>

                            <Caption style={{marginTop: 12, fontSize: 14}}>
                                {'Earliest available date: ' +
                                    (garage.available_time && DateTime.fromISO(garage.available_time).isValid
                                        ? DateTime.fromISO(garage.available_time).toLocal().toFormat('MMM d, yyyy')
                                        : 'N/A')}
                            </Caption>
                            <Divider style={{marginTop: 16}} />
                        </View>
                    </View>
                ))}
                {/*</View>*/}
            </RadioButton.Group>
            <BottomBar style={{justifyContent: 'center'}}>
                <ActionButton
                    title='Save'
                    fillColor={colors.primary}
                    disabled={!value || !appt}
                    width='100%'
                    onPress={() => {
                        appt &&
                            navigation.navigate('Scheduling', {
                                apptId: appt.id,
                                garageId: garages[parseInt(value, 10)].id,
                                date: garages[parseInt(value, 10)].available_time,
                            });
                    }}
                />
            </BottomBar>
        </PageContent>
    );
}

// const PageContent = styled.View`
//     padding: 16px;
//     gap: 16px;
//     flex: 1;
//     align-self: stretch;
//     background-color: white;
// `;
