import React, {useState, useEffect, useRef, useCallback} from 'react';
import {StatusBar} from 'expo-status-bar';
import {PanResponder, PanResponderInstance, StyleSheet, Text, View} from 'react-native';
import AppLoading from 'expo-app-loading';
import {NavigationContainer, useNavigation} from '@react-navigation/native';
import {createStackNavigator, StackNavigationProp} from '@react-navigation/stack';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {StackParamList} from 'linking';
import {Provider as PaperProvider} from 'react-native-paper';
import {aepLightTheme, aepLightTheme as theme} from 'theme';
import ActionButton from 'components/Button';
// import {History} from 'screens/history';
import {Settings} from 'screens/settings';
import {Appointments} from 'screens/appointments';
import ApptDetails from 'screens/appt-details';
import Scheduling from 'screens/scheduling';
import ChangeGarageModal from 'screens/change-garage';
import ChangeOwnershipModal from 'screens/change-ownership';
import {Vehicles} from 'screens/vehicles';
import VehicleDetails from 'screens/vehicle-details';
import {ServiceHistory} from 'screens/service-history';
import {RedFlagHistory} from 'screens/red-flag-history';
import {OwnershipHistory} from 'screens/ownership-history';
import Login from 'screens/login';
import {Appointment} from 'shared/appt.model';
import {ApptContext, AuthContext, SignOutContext} from 'shared/context';
import {User, UserRole} from 'shared/auth.model';
import {APIService} from 'api';
import * as Linking from 'expo-linking';
import {AppConfig} from 'app.config';
import Logout from 'screens/logout';
import {maybeCompleteAuthSession} from 'expo-web-browser';
import {hasOwnProperty} from 'types';

const Stack = createStackNavigator<StackParamList>();

// Navigation screen path hierarchy, for setting the URL & parameters for screens
// The top level is defined here so that child screens can export their own linking to be used here
// See https://reactnavigation.org/docs/5.x/configuring-links
const linking = {
    prefixes: [''],
    config: {
        screens: {
            Home: {
                path: '',
            },
            Appointments: 'appointments/:status',
            Scheduling: {
                path: 'scheduling/:apptId',
            },
            ModalScreens: {
                path: 'modal',
                screens: {
                    ChangeGarage: {
                        path: 'scheduling/:apptId?/change-garage',
                        exact: true,
                    },
                    ChangeOwnership: {
                        path: 'vehicle/:vehicleId/change-ownership',
                        exact: true,
                    },
                },
            },
            // History: 'history/:historyType',
            ApptDetails: 'appt/:apptId',
            Vehicles: 'vehicles',
            VehicleDetails: 'vehicle/:vehicleId',
            ServiceHistory: 'vehicle/:vehicleId/service-history',
            RedFlagHistory: 'vehicle/:vehicleId/red-flag-history',
            OwnershipHistory: 'vehicle/:vehicleId/ownership-history',
            Login: {path: 'login'},
            Logout: {path: 'logout'},
            Settings: 'settings',
        },
    },
};
function Home() {
    const navigation = useNavigation<StackNavigationProp<StackParamList>>();

    return (
        <View style={styles.container}>
            <Text>Open up App.tsx to start working on your app!</Text>

            <ActionButton
                title='Unscheduled'
                onPress={() => navigation.navigate('Appointments', {status: 'unscheduled', tab: undefined})}
            />
            <ActionButton title='Settings' onPress={() => navigation.navigate('Settings')} />
            <StatusBar style='auto' />
        </View>
    );
}
const ModalStack = createStackNavigator();
function ModalScreens() {
    return (
        <ModalStack.Navigator mode='modal'>
            <ModalStack.Screen name='ChangeGarage' component={ChangeGarageModal} options={{title: 'Choose Garage'}} />
            <ModalStack.Screen
                name='ChangeOwnership'
                component={ChangeOwnershipModal}
                options={{title: 'Change Ownership'}}
            />
        </ModalStack.Navigator>
    );
}

// Main entrypoint
export default function App() {
    const [isLoading, setIsLoading] = useState(true);
    const [isSignedIn, setIsSignedIn] = useState<null | boolean>(null);
    const [appt, setAppt] = useState<Appointment | null>(null);
    const [user, setUser] = useState<User | null>(null);
    const [initialUrl, setInitialUrl] = useState<string | null>(null);
    const navigationRef = useRef<any | null>(null); // not actually sure what the type is here, so use `any`
    const [authError, setAuthError] = useState<string | undefined>(undefined); // when !== undefined, forces logout route to be rendered
    const timerId = useRef<NodeJS.Timeout | null>(null);

    // Required when using WebBrowser, allows redirects back to the app to close the popup window
    // See https://docs.expo.dev/versions/v44.0.0/sdk/auth-session/#hooks
    maybeCompleteAuthSession();

    // Async function to pass to login screen
    // sets user context, stores token and re-renders routes on success
    // Note that this is only called with popup-based auth, not redirect
    const login = async (jwt: string) => {
        const api = new APIService();
        const user = await api.getSignedInUser(undefined, jwt);
        if (user) {
            await AppConfig.setToken(jwt);
            setUser(user);
            setAuthError(undefined);
            setIsSignedIn(true);
            setIsLoading(false);
        } else {
            throw new Error('User not found.');
        }
    };

    const logout = async () => {
        const jwt = (await AppConfig.getToken()) || '';
        await AppConfig.clearToken();
        const redirectUrl = Linking.createURL('/logout?');
        const encodedUrl = encodeURIComponent(redirectUrl);
        const logoutUrl = `${AppConfig.SERVER_BASE_URL}/auth/logout?redirectTo=${encodedUrl}&jwt=${jwt}`;
        await Linking.openURL(logoutUrl);
    };

    // Clear the received JWT from the URL
    // This makes the URL look nicer
    const clearJwtParam = () => navigationRef.current?.setParams({jwt: undefined});

    const panResponder = useRef<PanResponderInstance>(
        PanResponder.create({
            onStartShouldSetPanResponderCapture: () => {
                resetInactivityTimeout();
                return false;
            },
        })
    ).current;

    const resetInactivityTimeout = useCallback(() => {
        if (isSignedIn) {
            if (timerId.current) clearTimeout(timerId.current);

            timerId.current = setTimeout(() => {
                logout().catch((e) => {
                    console.log('Error logging out:', e);
                });
            }, 1800000);
        }
    }, [isSignedIn]);

    useEffect(() => {
        isSignedIn && resetInactivityTimeout();
    }, [isSignedIn, resetInactivityTimeout]);

    useEffect(() => {
        if (isSignedIn === null) {
            const api = new APIService();
            // First check for jwt in URL (from an earlier redirect)
            // If found, attempt to sign in with and save that JWT
            // Otherwise, sign in with saved JWT, if any
            // In both cases, state is updated depending on whether the login succeeded
            Linking.getInitialURL()
                .then((url) => {
                    setInitialUrl(url);
                    if (!url) return api.getSignedInUser();
                    const data = Linking.parse(url);

                    // Got here from a logout redirect; stop and render logout
                    if (data.path === 'logout') {
                        // Clear redirectTo here, so that logging in does not just take us back to `logout`
                        setInitialUrl(null);
                        return null;
                    }

                    // If no jwt in query parameter, try to sign in with saved credentials
                    if (!hasOwnProperty(data.queryParams, 'jwt') || !data.queryParams.jwt) return api.getSignedInUser();
                    console.log('Got initial jwt');
                    const jwt = data.queryParams.jwt;
                    return AppConfig.setToken(jwt).then(() =>
                        api.getSignedInUser(undefined, jwt).catch((err) => {
                            // unset status to prevent infinite login loop
                            // This way, we won't try to immediately log in again after trying with this JWT
                            err.status = undefined;
                            throw err;
                        })
                    );
                })
                .then((user) => {
                    APIService.deauthCallback = async () => {
                        console.log('Signed out.');
                        await AppConfig.clearToken();
                        setIsLoading(true); // loading must be temporarily set so the Logout route will be loaded
                        setUser(null);
                        setAuthError(''); // 'You have been logged out.'
                        setIsSignedIn(false);
                        setIsLoading(false);
                    };
                    setUser(user);
                    setAuthError(user ? undefined : ''); // 'You have been logged out.'
                    setIsSignedIn(!!user);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log('Error signing in: ', error);
                    AppConfig.clearToken();
                    setUser(null);
                    // if error is 401 Unauthorized, try logging in, otherwise render Logout with error message
                    setAuthError(error.status === 401 ? undefined : error.message || 'An error occurred.');
                    setIsSignedIn(false);
                    setIsLoading(false);
                });
        }
    }, [isSignedIn]);

    if (isLoading) return <AppLoading />;
    return (
        <PaperProvider theme={aepLightTheme} {...panResponder.panHandlers}>
            <SafeAreaProvider>
                <AuthContext.Provider value={{user: user as any, updateUser: setUser}}>
                    <ApptContext.Provider value={{value: appt, update: setAppt}}>
                        <SignOutContext.Provider value={logout}>
                            <NavigationContainer
                                linking={linking}
                                theme={theme}
                                ref={navigationRef}
                                onReady={clearJwtParam}
                            >
                                <Stack.Navigator initialRouteName={authError !== undefined ? 'Logout' : undefined}>
                                    {isSignedIn && user ? (
                                        <>
                                            <Stack.Screen
                                                name='Appointments'
                                                component={Appointments}
                                                options={{headerShown: false}}
                                                initialParams={{status: 'unscheduled'}}
                                            />
                                            <Stack.Screen
                                                name='ApptDetails'
                                                component={ApptDetails}
                                                options={{title: 'Appointment Detail'}}
                                            />
                                            <Stack.Screen
                                                name='Scheduling'
                                                component={Scheduling}
                                                options={{title: 'Scheduling Detail'}}
                                            />
                                            <Stack.Screen
                                                name='Vehicles'
                                                component={Vehicles}
                                                options={{headerShown: false}}
                                            />
                                            <Stack.Screen
                                                name='VehicleDetails'
                                                component={VehicleDetails}
                                                options={{title: 'Vehicle Details'}}
                                            />
                                            <Stack.Screen
                                                name='ServiceHistory'
                                                component={ServiceHistory}
                                                options={{title: 'Service History'}}
                                            />
                                            <Stack.Screen
                                                name='RedFlagHistory'
                                                component={RedFlagHistory}
                                                options={{title: 'Red Flag History'}}
                                            />
                                            <Stack.Screen
                                                name='OwnershipHistory'
                                                component={OwnershipHistory}
                                                options={{title: 'Ownership History'}}
                                            />
                                            <Stack.Screen
                                                name='ModalScreens'
                                                component={ModalScreens}
                                                options={{headerShown: false}}
                                            />
                                            {/*<Stack.Screen name='History' component={History} />*/}
                                            <Stack.Screen name='Settings' component={Settings} />
                                        </>
                                    ) : (
                                        <>
                                            <Stack.Screen name='Login' options={{headerShown: false}}>
                                                {(props) => <Login {...props} login={login} initialUrl={initialUrl} />}
                                            </Stack.Screen>
                                            <Stack.Screen name='Logout' options={{headerShown: false}}>
                                                {(props) => (
                                                    <Logout
                                                        {...props}
                                                        message={authError}
                                                        rtn={() => {
                                                            setIsSignedIn(null);
                                                            setIsLoading(true);
                                                        }}
                                                    />
                                                )}
                                            </Stack.Screen>
                                        </>
                                    )}
                                </Stack.Navigator>
                            </NavigationContainer>
                        </SignOutContext.Provider>
                    </ApptContext.Provider>
                </AuthContext.Provider>
            </SafeAreaProvider>
        </PaperProvider>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    },
});
