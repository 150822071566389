import ActionButton from '@components/Button';
import {useNavigation} from '@react-navigation/native';
import {StackScreenProps} from '@react-navigation/stack';
import {APIService} from 'api';
import {StackParamList} from 'linking';
import React, {useContext, useEffect, useState} from 'react';
import {View} from 'react-native';
import {Divider, useTheme, RadioButton, TextInput, List, Button} from 'react-native-paper';
import {Vehicle} from 'shared/appt.model';
import {User} from 'shared/auth.model';
import {AuthContext} from 'shared/context';
import {BottomBar, PageContent} from 'theme';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';

export default function ChangeOwnershipModal({route}: StackScreenProps<StackParamList, 'ChangeOwnership'>) {
    const user = useContext(AuthContext).user;
    const {colors} = useTheme();
    const navigation = useNavigation();
    const vehicleId = route.params?.vehicleId;

    const [textInputVisible, setTextInputVisible] = useState(false);
    const [memo, setMemo] = useState('');
    const [operators, setOperators] = useState<Array<User>>([]);
    const [vehicle, setVehicle] = useState<Vehicle | null>(null);
    const [value, setValue] = useState<any>('');
    const api = new APIService();

    useEffect(() => {
        const api = new APIService();
        if (vehicle?.operator)
            api.getOperators().then((ops) => {
                setOperators([user, ...ops].filter((op) => op.id !== vehicle?.operator));
            });
    }, [user, vehicle?.operator]);

    useEffect(() => {
        const api = new APIService();
        if (vehicleId)
            api.getVehicle(vehicleId).then((v) => {
                setVehicle(v);
            });
    }, [vehicleId]);

    useEffect(() => {
        if (value === '-1') setTextInputVisible(true);
        else setTextInputVisible(false);
    }, [value]);

    return (
        <PageContent paddingVertical>
            {vehicle?.operator_assignment?.[0]?.status === 'PENDING' && (
                <View>
                    <List.Item
                        title={'Memo: ' + vehicle.operator_assignment[0].memo}
                        left={(props) => (
                            <MaterialCommunityIcons name='information-outline' size={26} color={colors.accent} />
                        )}
                        style={{padding: 0}}
                        titleStyle={{color: colors.accent}}
                    />
                    <Divider />
                </View>
            )}
            <RadioButton.Group onValueChange={(newValue) => setValue(newValue)} value={value}>
                <RadioButton.Item label={'Change Department'} value={'-1'} color={colors.accent} mode='android' />
                {textInputVisible && (
                    <TextInput
                        mode='outlined'
                        placeholder={'Please provide name of new department, if known.'}
                        value={memo}
                        onChangeText={(text) => setMemo(text)}
                    />
                )}
                <Divider style={{marginTop: 16}} />
                {operators.length > 0 &&
                    operators.map((operator: User, index: number) => (
                        <View key={index.toString()}>
                            <RadioButton.Item
                                label={operator.first_name + ' ' + operator.last_name + ' - ' + operator.department}
                                value={index.toString()}
                                color={colors.primary}
                                mode='android'
                            />
                            <Divider style={{marginTop: 16}} />
                        </View>
                    ))}
            </RadioButton.Group>
            <BottomBar style={{justifyContent: 'center'}}>
                <ActionButton
                    title='Save'
                    fillColor={colors.primary}
                    disabled={!value || (value === '-1' && !memo)}
                    width='100%'
                    onPress={() => {
                        if (vehicle && vehicle.operator)
                            if (value === '-1' && memo)
                                api.reportOwnership({
                                    vehicle_id: vehicle.id,
                                    status: 'PENDING',
                                    memo: 'Department Change - ' + memo,
                                    updated_by: user.id,
                                    operator: vehicle.operator,
                                }).then(() => {
                                    navigation.navigate('VehicleDetails', {vehicleId: vehicleId});
                                });
                            else
                                api.updateOwnership({
                                    vehicle_id: vehicle.id,
                                    status: 'COMPLETE',
                                    memo: 'Updated by supervisor',
                                    updated_by: user.id,
                                    operator: operators[value].id,
                                    previous_op: vehicle.operator,
                                }).then(() => {
                                    navigation.navigate('VehicleDetails', {vehicleId: vehicleId});
                                });
                    }}
                />
            </BottomBar>
        </PageContent>
    );
}
