import React, {useEffect, useMemo, useState} from 'react';
import {Appointment} from 'shared/appt.model';
import {
    TouchableOpacity,
    View,
    StyleSheet,
    Modal,
    ScrollView,
    FlatList,
    useWindowDimensions,
    Dimensions,
} from 'react-native';
import {Text, Checkbox, Button, useTheme} from 'react-native-paper';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import ApptDetailCard from './RelatedAppointmentCard';

export type RelatedApptsPickerProps = {
    appts: Appointment[];
    selectedAppts?: Appointment[];
    selectAppts: (appts: Appointment[]) => any;
};

const RelatedApptsPicker: React.FC<RelatedApptsPickerProps> = (props) => {
    const {appts, selectedAppts, selectAppts} = props;

    const {colors} = useTheme();

    const [selecteds, setSelecteds] = useState<Appointment[]>([]);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedIdx, setSelectedIdx] = useState<boolean[]>([]);
    const {width} = useWindowDimensions();

    const selectionToggle = (selection: Appointment) => {
        const target = selecteds.find((appt) => appt.id === selection.id);
        if (target) {
            const index = selecteds.indexOf(target);
            selecteds.splice(index);
            setSelecteds([...selecteds]);
        } else {
            setSelecteds([...selecteds, selection]);
        }
    };

    const styles = StyleSheet.create({
        container: {
            borderColor: '#ced4da',
            flexDirection: 'row',
            alignItems: 'center',
        },
        modal: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#00000040',
        },
        innerContainer: {
            backgroundColor: 'white',
            padding: 4,
            borderRadius: width > 600 ? 8 : 0,
            maxWidth: '100%',

            maxHeight: width > 600 ? '80%' : '100%',
            height: width > 600 ? 'auto' : '100%',
            width: width > 600 ? 'auto' : '100%',
        },
        modalHeader: {
            padding: 16,
            borderBottomColor: '#ced4da',
            borderBottomWidth: 1,
            flexDirection: 'row',
            alignItems: 'center',
        },
        item: {
            flex: 1,
        },
        modalFooter: {
            flexDirection: 'row',
            padding: 16,
            boxShadow: '0px -4px 9px rgba(193, 193, 193, 0.2)',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
    });

    useEffect(() => {
        if (!appts) return;

        const indexes = Array<boolean>(appts.length);

        if (!selecteds || !selecteds.length) {
            setSelectedIdx([...indexes]);
            return;
        }

        for (let i = 0; i < appts.length; i++) indexes[i] = !!selecteds.find((appt) => appt.id === appts[i].id);

        setSelectedIdx([...indexes]);
    }, [appts, selecteds]);

    useEffect(() => {
        if (selectedAppts) setSelecteds(selectedAppts);
    }, [selectedAppts]);

    return (
        <>
            <TouchableOpacity onPress={() => setModalOpen(true)} style={styles.container}>
                <View style={{flex: 1, flexDirection: 'row', alignItems: 'center'}}>
                    <MaterialCommunityIcons name='plus-circle' size={26} color={colors.primary} />
                    <Text style={{marginStart: 10, fontSize: 16, fontWeight: 'bold'}}>Add Related Appointments</Text>
                </View>
            </TouchableOpacity>
            <Modal animationType={'fade'} transparent visible={modalOpen}>
                <View style={styles.modal}>
                    <View style={[styles.innerContainer]}>
                        <View style={styles.modalHeader}>
                            <Text style={{flex: 1, fontWeight: 'bold', color: '#333', fontSize: 18}}>
                                Related Appointments
                            </Text>
                            <TouchableOpacity onPress={() => setModalOpen(false)}>
                                <MaterialCommunityIcons name='close' size={12} />
                            </TouchableOpacity>
                        </View>

                        <ScrollView contentContainerStyle={{alignItems: 'center', marginVertical: 8}}>
                            <FlatList
                                data={appts}
                                renderItem={({item, index}) => (
                                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                        <Checkbox
                                            color={colors.primary}
                                            status={selectedIdx[index] ? 'checked' : 'unchecked'}
                                            onPress={() => {
                                                selectionToggle(item);
                                            }}
                                        />
                                        <ApptDetailCard
                                            key={index}
                                            appt={item}
                                            contentStyle={{maxWidth: '85%', minWidth: 320}}
                                        />
                                    </View>
                                )}
                                ItemSeparatorComponent={() => (
                                    <View
                                        style={{
                                            padding: 4,
                                        }}
                                    />
                                )}
                            />
                        </ScrollView>

                        <View style={styles.modalFooter}>
                            <Button
                                style={{marginRight: 10}}
                                uppercase={false}
                                mode='outlined'
                                onPress={() => setModalOpen(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                uppercase={false}
                                mode='contained'
                                onPress={() => {
                                    selectAppts(selecteds);
                                    setModalOpen(false);
                                }}
                            >
                                {selecteds.length ? `Add (${selecteds.length})` : 'Add'}
                            </Button>
                        </View>
                    </View>
                </View>
            </Modal>
        </>
    );
};

export default RelatedApptsPicker;
