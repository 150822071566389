import {StackScreenProps} from '@react-navigation/stack';
import {FlatList, StyleSheet, TouchableOpacity, View} from 'react-native';
import {Avatar, Chip, DataTable, List, Subheading, Text, TextInput as PaperTextInput} from 'react-native-paper';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import React, {useContext, useEffect, useState} from 'react';
import {Appointment, ApptStatusCode, apptStatusCodes} from 'shared/appt.model';
import {getInitials, getStatusColor, PageTitle} from 'theme';
import SegmentedControl from '@react-native-segmented-control/segmented-control';
import TopBar from 'components/TopBar';
import {AuthContext} from 'shared/context';
import {UserRole} from 'shared/auth.model';
import {StackParamList} from 'linking';
import {APIService} from 'api';
import SearchBar from 'components/SearchBar';
import {useIsFocused} from '@react-navigation/native';
import AppointmentCard from 'components/AppointmentCard';

function ApptCard({
    appt,
    avatarVisible = false,
    gotoDetails,
}: {
    appt: Appointment;
    avatarVisible?: boolean;
    gotoDetails: (app: Appointment) => any;
}) {
    const cardStyles = StyleSheet.create({
        card: {
            borderLeftColor: 'gray',
            borderLeftWidth: 6,
            flexGrowth: 0,
            flexBasis: 'auto',
            flexShrink: 0,
            backgroundColor: 'white',
            borderRadius: 4,
            marginHorizontal: 8,
            marginVertical: 4,
            paddingHorizontal: 16,
            paddingVertical: 16,
            gap: 4,
            shadowColor: '#000',
            shadowOffset: {
                width: 0,
                height: 1,
            },
            shadowOpacity: 0.25,
            shadowRadius: 5,
            elevation: 1,
            minWidth: 350,
        },
    });
    let status = appt.status ? appt.status[0].status_code : 'NONE';
    const statusTime = appt?.status?.[0].update_time;
    const fullname = appt.operator ? appt.operator.first_name + ' ' + appt.operator?.last_name : null;
    const desc =
        appt.work_type === 'PM'
            ? 'Preventative Maintenance Needed'
            : appt.work_type === 'CMPWO'
            ? 'Recall is Required'
            : 'Maintenance Required';

    if (status === 'VCOMP') status = 'COMPLETED';
    return (
        <TouchableOpacity
            style={[cardStyles.card, {borderLeftColor: getStatusColor(status)}]}
            onPress={() => gotoDetails(appt)}
            delayPressIn={100}
        >
            <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                <Text style={{fontWeight: 'bold', color: getStatusColor(status)}}>{status}</Text>
                {(status === 'COMPLETED' || status === 'VCOMP') && statusTime && (
                    <Text> - {new Date(statusTime).toDateString()}</Text>
                )}
            </View>
            <Subheading style={{fontWeight: '600'}}>{(appt.wonum ? appt.wonum : 'N/A') + ' - ' + desc}</Subheading>
            <List.Item
                title={appt.vehicle?.description ? appt.vehicle.description : 'No Vehicle Description'}
                description={appt.vehicle?.alias ? appt.vehicle.alias : 'No Vehicle Alias'}
                left={(props) => <MaterialCommunityIcons name='car-back' size={26} color='grey' />}
                style={{padding: 0}}
            />
            {appt.vendor_performed && (
                <Chip style={{backgroundColor: '#3F569F', alignSelf: 'flex-start'}} textStyle={{color: 'white'}}>
                    Vendor
                </Chip>
            )}
            {avatarVisible && <Avatar.Text size={36} label={fullname ? getInitials(fullname) : 'N/A'} />}
        </TouchableOpacity>
    );
}

export function Appointments({navigation, route}: StackScreenProps<StackParamList, 'Appointments'>) {
    const [tabIndex, setTabIndex] = useState(route.params.tab === 'crew' ? 1 : 0);
    const [status, setStatus] = useState(route.params.status);
    const user = useContext(AuthContext).user;
    const [apptList, setApptList] = useState<Appointment[]>([]);
    const [pagedApptList, setPagedApptList] = useState<Appointment[]>([]);

    const isFocused = useIsFocused();
    const gotoDetails = (appt: Appointment) => {
        if (appt.status && appt.status[0].status_code === 'UNSCHEDULED' && appt.vendor_performed !== true)
            navigation.navigate('Scheduling', {apptId: appt.id});
        else navigation.navigate('ApptDetails', {apptId: appt.id});
    };

    const [searchQuery, setSearchQuery] = useState('');
    const [onSearch, setOnSearch] = useState(() => (query: string) => {
        setSearchQuery(query);
        setPageData({page: 1, pageCount: 1});
    });
    const [pageData, setPageData] = useState({page: 1, pageCount: 1});
    const requestsPerPage = 10;

    const getTitle = () => {
        switch (status) {
            default: {
                return status.toLocaleUpperCase();
            }
            case 'unscheduled': {
                return 'Awaiting Schedule';
            }
            case 'confirmed': {
                return 'My Scheduled';
            }
            case 'completed': {
                return 'Service History';
            }
        }
    };

    useEffect(() => {
        const requestCount = apptList.length;
        const pageCount = requestCount ? Math.ceil(requestCount / requestsPerPage) : 1;
        const page = Math.min(pageData.page, pageCount);
        const newApptList = apptList.slice((page - 1) * requestsPerPage, page * requestsPerPage);
        setPagedApptList(newApptList);
        setPageData({page, pageCount});
    }, [pageData.page, apptList]);

    useEffect(() => {
        setStatus(route.params.status);
        const status = route.params.status.toLocaleUpperCase() as ApptStatusCode;

        setApptList([]); // clear list until data is fetched

        if (!apptStatusCodes.includes(status)) return;

        const api = new APIService();

        if (searchQuery) {
            if (tabIndex === 0) api.searchMyAppointments(searchQuery, status).then(setApptList);
            else if (tabIndex === 1) api.searchCrewAppointments(searchQuery, status).then(setApptList);
        } else {
            if (tabIndex === 0) api.getMyAppointments(status).then(setApptList);
            else if (tabIndex === 1) api.getCrewAppointments(status).then(setApptList);
        }
    }, [route.params.status, searchQuery, tabIndex, isFocused]);

    return (
        <View style={styles.verticalContainer}>
            <TopBar />
            <PageTitle>{getTitle()}</PageTitle>
            {user.user_role === UserRole.SUPERVISOR && (
                <View style={{paddingHorizontal: 16}}>
                    <SegmentedControl
                        values={['Mine', 'Crew']}
                        selectedIndex={tabIndex}
                        onChange={(event) => {
                            setTabIndex(event.nativeEvent.selectedSegmentIndex);
                            if (event.nativeEvent.selectedSegmentIndex === 0) navigation.setParams({tab: 'mine'});
                            else if (event.nativeEvent.selectedSegmentIndex === 1) navigation.setParams({tab: 'crew'});
                        }}
                        appearance={'light'}
                    />
                </View>
            )}
            <View style={{paddingHorizontal: 24}}>
                <SearchBar onSearch={onSearch} />
                <DataTable.Pagination
                    page={pageData.page - 1}
                    numberOfPages={pageData.pageCount}
                    onPageChange={(page) => setPageData({...pageData, page: page + 1})}
                    selectPageDropdownLabel={'Page'}
                    label={`Page ${pageData.page} of ${pageData.pageCount}`}
                    showFastPaginationControls
                />
            </View>
            <View style={{flex: 1, alignItems: 'center'}}>
                <FlatList
                    data={pagedApptList}
                    renderItem={({item}) => (
                        <AppointmentCard
                            appt={item}
                            gotoDetails={gotoDetails}
                            avatarVisible={user.user_role === UserRole.SUPERVISOR && tabIndex === 1}
                        />
                    )}
                    keyExtractor={(item: Appointment) => item.id.toString()}
                />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    verticalContainer: {
        flex: 1,
        backgroundColor: '#fff',
    },
});
