// Module for 'complex' parameterized/generic types used throughout the project

// TS-safe way of checking for props (from https://fettblog.eu/typescript-hasownproperty/)
// eslint-disable-next-line @typescript-eslint/ban-types
export function hasOwnProperty<X extends {}, Y extends PropertyKey>(obj: X, prop: Y): obj is X & Record<Y, unknown> {
    return obj.hasOwnProperty(prop);
}
export type ValueOf<T> = T[keyof T];

// Extracts nullable properties from a type, as a union of keys
export type NullableProps<T> = {
    [P in keyof T]-?: null extends T[P] ? P : never;
}[keyof T];

// Extracts properties that can be records
export type RecordProps<T extends Record<string, any>> = {
    [P in keyof T]-?: T[P] extends Record<any, any> ? P : never;
}[keyof T];

// Extracts the prop names of async callables
export type AsyncCallableProps<T> = {
    [P in keyof T]-?: T[P] extends (...args: any) => Promise<any> ? P : never;
}[keyof T];

// Extracts thenable type per https://stackoverflow.com/a/49889856
export type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;

// Removes null & undefined from properties' types
// https://stackoverflow.com/a/53050575
type NonNullableFields<T> = {
    [P in keyof T]-?: NonNullable<T[P]>;
};

export type OptionalExcept<T, K extends keyof T> = Partial<Omit<T, K>> & Pick<T, K>; // Mark all properties except those in K as optional
export type RequiredProp<T, K extends keyof T> = NonNullableFields<Pick<T, K>> & Omit<T, K>; // mark properties as required
export type AtLeastOne<T, U = {[K in keyof T]: Pick<T, K>}> = Partial<T> & U[keyof U]; // From https://stackoverflow.com/a/48244432
export type OptionalIfNull<T> = Partial<Pick<T, NullableProps<T>>> & Omit<T, NullableProps<T>>;
