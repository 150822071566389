// App-wide configuration: API URLs, tokens, storage etc
import Storage from 'react-native-storage';
import {hasOwnProperty} from 'types';
import Constants from 'expo-constants';

// See https://github.com/sunnylqm/react-native-storage
const storage = new Storage({
    storageBackend: localStorage,
    defaultExpires: null,
});

export class AppConfig {
    // See /app.config.js and the Dockerfile for more info as to how this is set
    public static readonly SERVER_BASE_URL: string =
        (Constants.manifest?.extra?.SERVER_BASE_URL as string | undefined) ||
        (__DEV__ ? 'http://localhost:3000' : 'https://aepdev.interprosoft.com');
    public static API_BASE_URL = AppConfig.SERVER_BASE_URL + '/api';

    private static AUTH_TOKEN_KEY = 'AUTH-TOKEN';

    /** Asynchronously get JWT token from storage, or `null` if not found. */
    static async getToken(): Promise<string | null> {
        try {
            const data = await storage.load({key: AppConfig.AUTH_TOKEN_KEY});
            if (typeof data === 'object' && hasOwnProperty(data, 'token') && typeof data.token === 'string')
                return data.token;

            return null;
        } catch (error) {
            return null;
        }
    }

    /** Save JWT token into local storage*/
    static async setToken(token: string) {
        await storage.save({
            key: AppConfig.AUTH_TOKEN_KEY,
            data: {token},
        });
        return token;
    }

    /** Clear JWT token from storage */
    static async clearToken() {
        try {
            await storage.remove({key: AppConfig.AUTH_TOKEN_KEY});
        } catch (error) {
            console.log('Error clearing data:', error);
        }
    }
}
