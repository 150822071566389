import React, {useContext, useState} from 'react';
import {Image, StyleSheet, View} from 'react-native';
import {Avatar, Divider, IconButton, Menu, Text, useTheme} from 'react-native-paper';
import {useNavigation} from '@react-navigation/native';
import {AuthContext, SignOutContext} from 'shared/context';
import {UserRole, User} from 'shared/auth.model';

const styles = StyleSheet.create({
    horizontalContainer: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: '#fff',
        justifyContent: 'space-between',
        paddingHorizontal: 16,
        paddingVertical: 16,
    },
});

export default function TopBar() {
    const navigation = useNavigation();
    const {colors} = useTheme();
    const user = useContext(AuthContext).user as User | null;
    const logout = useContext(SignOutContext);
    const [menuVisible, setMenuVisible] = useState(false);
    return (
        <View>
            <>
                <View style={styles.horizontalContainer}>
                    <Image source={require('assets/aep-logo.png')} style={{width: 76, height: 39}} />
                    {user && (
                        <View style={{flexDirection: 'row', flex: 1, justifyContent: 'flex-end', alignItems: 'center'}}>
                            {user.user_role === UserRole.SUPERVISOR && (
                                <View style={{alignItems: 'flex-end', marginRight: 8}}>
                                    <Text style={{fontWeight: 'bold'}}>{user.first_name + ' ' + user.last_name}</Text>
                                    <Text style={{opacity: 0.8, textTransform: 'capitalize'}}>{user.user_role}</Text>
                                </View>
                            )}
                            <Avatar.Text size={36} label={user ? user.first_name[0] + user.last_name[0] : ''} />
                            <Menu
                                visible={menuVisible}
                                onDismiss={() => setMenuVisible(false)}
                                anchor={
                                    <IconButton
                                        icon='menu-down'
                                        color={'#333'}
                                        size={20}
                                        onPress={() => setMenuVisible(true)}
                                    />
                                }
                            >
                                <Menu.Item
                                    icon='calendar-today'
                                    onPress={() => {
                                        setMenuVisible(false);
                                        navigation.navigate('Appointments', {status: 'confirmed'});
                                    }}
                                    title='My Scheduled'
                                />
                                <Menu.Item
                                    icon='calendar-clock'
                                    onPress={() => {
                                        setMenuVisible(false);
                                        navigation.navigate('Appointments', {status: 'unscheduled'});
                                    }}
                                    title='Awaiting Schedule'
                                />
                                <Menu.Item
                                    icon='history'
                                    onPress={() => {
                                        setMenuVisible(false);
                                        navigation.navigate('Appointments', {status: 'completed'});
                                    }}
                                    title='Service History'
                                />
                                <Menu.Item
                                    icon='car-back'
                                    onPress={() => {
                                        setMenuVisible(false);
                                        navigation.navigate('Vehicles');
                                    }}
                                    title='My Vehicles'
                                />
                                <Divider />
                                <Menu.Item
                                    icon={'account-settings-outline'}
                                    onPress={() => {
                                        setMenuVisible(false);
                                        navigation.navigate('Settings');
                                    }}
                                    title='Settings'
                                />
                                <Menu.Item
                                    icon='logout'
                                    onPress={() => {
                                        if (logout) {
                                            // TODO: Show error to user on logout
                                            logout().catch((e) => {
                                                console.log('Error logging out:', e);
                                                setMenuVisible(false);
                                            });
                                            return;
                                        }
                                        setMenuVisible(false);
                                        console.log('Pressed');
                                    }}
                                    title='Log Out'
                                    titleStyle={{color: colors.accent}}
                                />
                            </Menu>
                        </View>
                    )}
                </View>
                <Divider />
            </>
        </View>
    );
}
