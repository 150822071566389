import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import {StackScreenProps} from '@react-navigation/stack';
import TopBar from 'components/TopBar';
import {StackParamList} from 'linking';
import React, {useContext, useEffect, useState} from 'react';
import {View, StyleSheet, TouchableOpacity, FlatList} from 'react-native';
import {Avatar, List, Text, Subheading, useTheme, Chip} from 'react-native-paper';
import {Appointment} from 'shared/appt.model';
import {getInitials, getStatusColor, PageTitle, Row} from 'theme';
import {AuthContext} from 'shared/context';
import {APIService} from 'api';
import {UserRole} from 'shared/auth.model';

function ServiceCard({appt, avatarVisible = false}: {appt: any; avatarVisible?: boolean}) {
    const fullname = appt.operator?.first_name + ' ' + appt.operator?.last_name;
    const status = appt.status ? appt.status[0].status_code : 'NONE';
    return (
        <TouchableOpacity
            style={[cardStyles.card, {borderLeftColor: getStatusColor(status)}]}
            onPress={() => console.log('Service History Card Pressed')}
            delayPressIn={100}
        >
            <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                <Text style={{fontWeight: 'bold', color: getStatusColor(status)}}>{status}</Text>
                {appt.scheduled_endtime && <Text> - {new Date(appt.scheduled_endtime).toDateString()}</Text>}
            </View>
            <Subheading style={{fontWeight: '600'}}>
                {(appt.wonum ? appt.wonum : 'No Work Order Number') +
                    ' - ' +
                    (appt.description ? appt.description : 'No Appointment Description')}
            </Subheading>
            <List.Item
                title={appt.vehicle?.description ? appt.vehicle.description : 'No Vehicle Description'}
                left={(props) => <MaterialCommunityIcons name='car-back' size={26} color='grey' />}
                style={{padding: 0}}
            />
            {appt.vendor_performed && (
                <Chip style={{backgroundColor: '#3F569F', alignSelf: 'flex-start'}} textStyle={{color: 'white'}}>
                    Vendor
                </Chip>
            )}
            {avatarVisible && <Avatar.Text size={36} label={getInitials(fullname)} />}
        </TouchableOpacity>
    );
}

export function ServiceHistory({route}: StackScreenProps<StackParamList, 'ServiceHistory'>) {
    const [apptList, setApptList] = useState<Appointment[]>([]);
    const user = useContext(AuthContext).user;

    useEffect(() => {
        const api = new APIService();
        api.getVehicleAppointments(route.params.vehicleId).then(setApptList);
    }, [route.params.vehicleId]);

    return (
        <View style={styles.verticalContainer}>
            <TopBar />
            {/*<PageTitle>Service History</PageTitle>*/}
            <View style={{flex: 1, alignItems: 'center'}}>
                {/*<SearchBar />*/}
                {/*<DataTable.Pagination */}
                {/*/>*/}
                <FlatList
                    data={apptList}
                    renderItem={({item}) => (
                        <ServiceCard appt={item} avatarVisible={user.user_role === UserRole.SUPERVISOR} />
                    )}
                    keyExtractor={(item: Appointment) => item.id.toString()}
                />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    verticalContainer: {
        flex: 1,
        backgroundColor: '#fff',
    },
});

const cardStyles = StyleSheet.create({
    card: {
        borderLeftColor: 'gray',
        borderLeftWidth: 6,
        flexGrowth: 0,
        flexBasis: 'auto',
        flexShrink: 0,
        backgroundColor: 'white',
        borderRadius: 4,
        marginHorizontal: 8,
        marginVertical: 4,
        paddingHorizontal: 16,
        paddingVertical: 16,
        gap: 4,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.25,
        shadowRadius: 5,
        elevation: 1,
        minWidth: 350,
    },
});
