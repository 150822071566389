// Stub login screen
// Uses the server's auth route to login and receive a JWT token
import React, {useState, useEffect, useContext} from 'react';
import {StyleSheet, View, Platform} from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import * as Linking from 'expo-linking';
import {useIsFocused} from '@react-navigation/native';
import {AppConfig} from 'app.config';
import TopBar from 'components/TopBar';
import {ActivityIndicator, Divider, Text, Subheading, useTheme} from 'react-native-paper';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import {hasOwnProperty} from 'types';

const ICON_SIZE = 64; // Used instead of 'large' so that ActivityIndicator and icons are the same size

type LoginFunc = (jwt: string) => Promise<any>;

// Strip trailing JWT parameter from URL
const stripJwt = (url: string): string => {
    let i = url.indexOf('&jwt=');
    if (i >= 0) return url.slice(undefined, i);
    i = url.indexOf('jwt=');
    if (i >= 0) return url.slice(undefined, i);
    return url;
};

export default function Login({login, initialUrl}: {login: LoginFunc; initialUrl: string | null}) {
    const [isLoading, setIsLoading] = useState(false);
    const [status, setStatus] = useState('');
    const [error, setError] = useState(false);
    const [result, setResult] = useState('');
    const isFocused = useIsFocused();
    const {colors} = useTheme();

    const showError = (message?: string) => {
        setIsLoading(false);
        setStatus(message || '');
        setError(true);
    };

    if (initialUrl && !initialUrl.includes('?')) initialUrl = initialUrl + '?'; // redirect URL must accept query params

    useEffect(() => {
        if (!isFocused || result) return;

        const redirectUrl = stripJwt(initialUrl || Linking.createURL('/?'));
        const encodedUrl = encodeURIComponent(redirectUrl);
        console.log('encoded URL:', encodedUrl);
        setIsLoading(true);
        setStatus('Please wait while SSO authentication completes');
        // Pass SAML RelayState per https://stackoverflow.com/a/46555155
        // If overriding SSO auth on the server, you can pass an id here to log in as that user
        const authUrl = `${AppConfig.SERVER_BASE_URL}/auth/login?id=${1}&redirectTo=${encodedUrl}`;
        Platform.OS === 'web'
            ? Linking.openURL(authUrl)
            : WebBrowser.openAuthSessionAsync(authUrl, redirectUrl)
                  .then((res) => {
                      if (res.type === 'dismiss') {
                          showError('The authentication window was dismissed. Refresh this page to continue.');
                          return;
                      } else if (!res || res.type !== 'success') {
                          showError('Error connecting to authentication server.');
                          return;
                      }
                      try {
                          setError(false);
                          const url: string | undefined = (res as any).url;
                          if (!url) return showError('Missing URL');
                          const data = Linking.parse(url);
                          if (!hasOwnProperty(data.queryParams, 'jwt') || !data.queryParams.jwt)
                              return showError('Missing JWT from server');
                          const jwt = data.queryParams.jwt;
                          setStatus('Logging you in');
                          setResult(jwt);
                          login(jwt).catch((e) => {
                              showError(e.message);
                              setResult('error'); // set result so that authentication doesn't occur again
                          });
                      } catch (e) {
                          console.log(e);
                          showError('An authentication error occurred.');
                      }
                  })
                  .catch((err) => {
                      setIsLoading(false);
                      setError(true);
                      if (typeof err.message === 'string') {
                          const errMessage = err.message.toLowerCase();
                          if (errMessage.includes('popup') && errMessage.includes('blocked'))
                              setStatus('Authentication popup was blocked. Please enable popups and refresh the page.');
                          else setStatus(err.message);
                      } else {
                          setStatus('Error connecting to authentication server.');
                      }
                  });
    }, [login, isFocused, result, initialUrl]);
    return (
        <View style={styles.verticalContainer}>
            <TopBar />
            {/*<PageTitle>Login</PageTitle>*/}
            <View style={{flex: 1, alignItems: 'center', marginTop: '10%'}}>
                <ActivityIndicator animating={isLoading} size={ICON_SIZE} />
                {error && <MaterialCommunityIcons name='alert-circle' size={ICON_SIZE} color={colors.primary} />}
                <Subheading style={{marginTop: 20}}>{status}</Subheading>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    verticalContainer: {
        flex: 1,
        backgroundColor: '#fff',
    },
});
