import React, {useEffect, useMemo, useState} from 'react';
import {TouchableOpacity, View, Text, Modal, FlatList, StyleSheet} from 'react-native';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import {ScrollView} from 'react-native-gesture-handler';

export type SelectData = {
    label: string;
    value: any;
};

export type DropDownSelectProps = {
    data: SelectData[];
    label: string;
    selection: any;
    onSelect: (value: any) => void;
};

const DropDownSelect: React.FC<DropDownSelectProps> = (props) => {
    const {data, label, selection, onSelect} = props;
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    return useMemo(
        () => (
            <>
                <TouchableOpacity onPress={() => setModalOpen(!modalOpen)} style={styles.container}>
                    <View style={{flex: 1}}>
                        <Text>{selection.label}</Text>
                    </View>
                    <MaterialCommunityIcons name='chevron-down' size={12} />
                </TouchableOpacity>
                <Modal
                    animationType={'fade'}
                    transparent
                    visible={modalOpen}
                    onRequestClose={() => setModalOpen(false)}
                >
                    <TouchableOpacity style={styles.modal} onPress={() => setModalOpen(false)} activeOpacity={1}>
                        <View style={styles.innerContainer}>
                            <View style={styles.modalHeader}>
                                <Text style={{flex: 1, fontWeight: 'bold'}}>{label}</Text>
                                <TouchableOpacity onPress={() => setModalOpen(false)}>
                                    <MaterialCommunityIcons name='close' size={12} />
                                </TouchableOpacity>
                            </View>
                            <ScrollView>
                                <FlatList
                                    data={data}
                                    renderItem={({item, index}) => (
                                        <TouchableOpacity
                                            key={index}
                                            style={{
                                                flex: 1,
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                padding: 16,
                                            }}
                                            onPress={() => {
                                                onSelect(item.value);
                                                setModalOpen(false);
                                            }}
                                        >
                                            <Text style={styles.item}>{item.label}</Text>
                                            {selection.value === item.value && (
                                                <MaterialCommunityIcons name='check-circle' size={20} />
                                            )}
                                        </TouchableOpacity>
                                    )}
                                    ItemSeparatorComponent={() => (
                                        <View
                                            style={{
                                                width: '100%',
                                                height: 1,
                                                backgroundColor: '#e9ecef',
                                            }}
                                        />
                                    )}
                                />
                            </ScrollView>
                        </View>
                    </TouchableOpacity>
                </Modal>
            </>
        ),
        [data, label, modalOpen, selection, onSelect]
    );
};

const styles = StyleSheet.create({
    container: {
        borderColor: '#ced4da',
        borderWidth: 1,
        padding: 12,
        margin: 4,
        flexDirection: 'row',
        alignItems: 'center',
    },
    modal: {
        flex: 1,
        justifyContent: 'center',
        backgroundColor: '#00000040',
        padding: 24,
    },
    innerContainer: {
        backgroundColor: 'white',
        borderRadius: 8,
        maxHeight: '80%',
    },
    modalHeader: {
        padding: 16,
        borderBottomColor: '#ced4da',
        borderBottomWidth: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    item: {
        flex: 1,
    },
});

export default DropDownSelect;
