import React, {useContext, useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {Divider, FAB, Menu, useTheme} from 'react-native-paper';
import {useNavigation} from '@react-navigation/native';
import {Vehicle} from 'shared/appt.model';
import {UserRole} from 'shared/auth.model';
import {AuthContext} from 'shared/context';

const styles = StyleSheet.create({
    stickBottomContainer: {
        position: 'absolute',
        right: 0,
        bottom: 16,
    },
    fab: {
        margin: 16,
    },
});

// Currently static fab menu content for supervisor edit vehicle detail
export default function FABMenu({vehicle, handleClick}: {vehicle: Vehicle; handleClick?: any}) {
    const navigation = useNavigation();
    const {colors} = useTheme();
    const [menuVisible, setMenuVisible] = useState(false);
    const user = useContext(AuthContext).user;

    return (
        <View style={styles.stickBottomContainer}>
            <Menu
                visible={menuVisible}
                onDismiss={() => setMenuVisible(false)}
                anchor={
                    <FAB
                        style={styles.fab}
                        icon='pencil-outline'
                        theme={{colors: {accent: colors.primary}}}
                        onPress={() => setMenuVisible(true)}
                    />
                }
            >
                {user.user_role === UserRole.SUPERVISOR && (
                    <>
                        <Menu.Item
                            disabled={!!vehicle.red_flag_track?.[0].is_flagged}
                            icon='account-arrow-left-outline'
                            onPress={() => {
                                navigation.navigate('ModalScreens', {
                                    screen: 'ChangeOwnership',
                                    params: {vehicleId: vehicle.id},
                                });
                                setMenuVisible(false);
                            }}
                            title='Change Ownership'
                        />
                    </>
                )}
                <Menu.Item
                    icon='page-previous-outline'
                    onPress={() => {
                        navigation.navigate('ApptDetails', {apptId: vehicle.latest_pm_work?.id});
                        setMenuVisible(false);
                    }}
                    title='Review and Reschedule'
                />
                <Menu.Item
                    disabled={
                        user.id !== vehicle.red_flag_track?.[0].updated_by && !!vehicle.red_flag_track?.[0].is_flagged
                    }
                    icon='flag-variant-outline'
                    onPress={() => {
                        handleClick('red-flag');
                        setMenuVisible(false);
                    }}
                    title={(vehicle.red_flag_track?.[0].is_flagged ? 'Remove' : 'Mark') + ' Red Flag'}
                />
                <Divider />
                <Menu.Item
                    onPress={() => {
                        navigation.navigate('ServiceHistory', {vehicleId: vehicle.id});
                        setMenuVisible(false);
                    }}
                    title='Service History'
                />
                <Menu.Item
                    onPress={() => {
                        navigation.navigate('RedFlagHistory', {vehicleId: vehicle.id});
                        setMenuVisible(false);
                    }}
                    title='Red Flag History'
                />
                <Menu.Item
                    onPress={() => {
                        navigation.navigate('OwnershipHistory', {vehicleId: vehicle.id});
                        setMenuVisible(false);
                    }}
                    title='Ownership History'
                />
            </Menu>
        </View>
    );
}
