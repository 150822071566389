import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import {StackScreenProps} from '@react-navigation/stack';
import {APIService} from 'api';
import TopBar from 'components/TopBar';
import {StackParamList} from 'linking';
import React, {useEffect, useState} from 'react';
import {View, StyleSheet, TouchableOpacity, FlatList} from 'react-native';
import {Avatar, List, Subheading, Text, useTheme} from 'react-native-paper';
import {VehicleOperatorAssignment} from 'shared/appt.model';
import {User} from 'shared/auth.model';
import {getInitials, PageTitle, Row} from 'theme';

function OwnershipCard({operatorAssignment}: {operatorAssignment: VehicleOperatorAssignment}) {
    const [updater, setUpdater] = useState<User>({
        first_name: 'Not',
        last_name: 'Available',
        id: -1,
    } as User);

    const [operator, setOperator] = useState<User>({
        first_name: 'Not',
        last_name: 'Available',
        id: -1,
    } as User);

    useEffect(() => {
        const api = new APIService();
        if (operatorAssignment.updated_by)
            api.getUser(operatorAssignment.updated_by).then((u) => {
                setUpdater(u);
            });
        if (operatorAssignment.operator)
            api.getUser(operatorAssignment.operator).then((op) => {
                setOperator(op);
            });
    }, [operatorAssignment]);

    const updateTime: Date = new Date(Date.parse(operatorAssignment.update_time));

    return (
        <TouchableOpacity
            style={[cardStyles.card]}
            onPress={() => console.log('Ownership Card Pressed')}
            delayPressIn={100}
        >
            {/*<Row alignment={'center'}>*/}
            {/*    <Text>#{operatorAssignment.id}</Text>*/}
            {/*</Row>*/}
            <Subheading style={{fontWeight: '600'}}>{operatorAssignment.memo}</Subheading>
            <List.Item
                title={updateTime.toLocaleString()}
                left={(props) => <MaterialCommunityIcons name='calendar-clock' size={26} color='grey' />}
                style={{padding: 0}}
            />
            <Row alignment={'center'} justify={'start'}>
                <Avatar.Text size={36} label={getInitials(operator.first_name + ' ' + operator.last_name)} />
            </Row>
        </TouchableOpacity>
    );
}

export function OwnershipHistory({route}: StackScreenProps<StackParamList, 'OwnershipHistory'>) {
    const [ownerships, setOwnerships] = useState<VehicleOperatorAssignment[]>([]);

    useEffect(() => {
        const api = new APIService();
        api.getOperatorAssignments(route.params.vehicleId).then((voas) => {
            setOwnerships(voas);
        });
    }, [route.params.vehicleId]);

    return (
        <View style={styles.verticalContainer}>
            <TopBar />
            {/*<PageTitle>Ownership History</PageTitle>*/}
            <View style={{flex: 1, alignItems: 'center'}}>
                {/*<SearchBar />*/}
                {/*<DataTable.Pagination */}
                {/*    page */}
                {/*/>*/}
                <FlatList
                    data={ownerships}
                    renderItem={({item}) => <OwnershipCard operatorAssignment={item} />}
                    keyExtractor={(item: VehicleOperatorAssignment) => item.id.toString()}
                />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    verticalContainer: {
        flex: 1,
        backgroundColor: '#fff',
    },
});

const cardStyles = StyleSheet.create({
    card: {
        flexGrowth: 0,
        flexBasis: 'auto',
        flexShrink: 0,
        backgroundColor: 'white',
        borderRadius: 4,
        marginHorizontal: 8,
        marginVertical: 4,
        paddingHorizontal: 16,
        paddingVertical: 16,
        gap: 4,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.25,
        shadowRadius: 5,
        elevation: 1,
        minWidth: 350,
    },
});
