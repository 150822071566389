import React, {createContext} from 'react';
import {User} from './auth.model';
import {Appointment} from 'shared/appt.model';

export const AuthContext = createContext<{user: User; updateUser: (value: User) => void}>({
    // @ts-ignore We use `User` instead of `User | null` here so that routes can expect an actual User object.
    // Since signed-in routes will not be rendered unless a User is present, this is safe
    user: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    updateUser: (value) => {},
});

export const ApptContext = createContext<{value: Appointment | null; update: (value: Appointment | null) => void}>({
    value: null,
    // Disable ESLint since the initial `update` function is just a dummy; it is provided where the ApptContext is
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    update: (value) => {},
});

// Store the stateful sign-out function in a context, so that any nested component can access it
export const SignOutContext = React.createContext<(() => Promise<void>) | null>(null);
