// Stub log out screen
// Just displays a logout message and can return to the login screen
import React from 'react';
import {StyleSheet, View} from 'react-native';
import TopBar from 'components/TopBar';
import ActionButton from 'components/Button';
import {useTheme, Subheading} from 'react-native-paper';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import {useNavigation} from '@react-navigation/native';
import {StackNavigationProp} from '@react-navigation/stack';
import {StackParamList} from 'linking';

export default function Logout({message, rtn}: {message?: string; rtn: () => void}) {
    const {colors} = useTheme();
    const navigation = useNavigation<StackNavigationProp<StackParamList>>();
    message = message || 'You have been logged out.';
    return (
        <View style={styles.verticalContainer}>
            <TopBar />
            <View style={{flex: 1, alignItems: 'center', marginTop: '10%'}}>
                <MaterialCommunityIcons name='logout' size={128} color={colors.primary} />
                <Subheading style={{marginTop: 20}}>{message}</Subheading>
                <ActionButton
                    style={{marginTop: 20}}
                    width={'10%'}
                    title='Home'
                    onPress={() => {
                        // Ideally we'd navigate to the root of the app, but this works for now
                        navigation.reset({
                            index: 0,
                            routes: [{name: 'Login'}],
                        });
                    }}
                />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    verticalContainer: {
        flex: 1,
        backgroundColor: '#fff',
    },
});
