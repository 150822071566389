import {Appointment} from 'shared/appt.model';
import React from 'react';
import {TouchableOpacity, View, StyleSheet} from 'react-native';
import {Avatar, List, Subheading, Text, Chip} from 'react-native-paper';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import {getInitials, getStatusColor} from 'theme';

export type AppointmentCardProps = {
    appt: Appointment;
    avatarVisible?: boolean;
    gotoDetails: (app: Appointment) => any;
};

const AppointmentCard: React.FC<AppointmentCardProps> = (props) => {
    const {appt, avatarVisible, gotoDetails} = props;

    const cardStyles = StyleSheet.create({
        card: {
            borderLeftColor: 'gray',
            borderLeftWidth: 6,
            flexGrowth: 0,
            flexBasis: 'auto',
            flexShrink: 0,
            backgroundColor: 'white',
            borderRadius: 4,
            marginHorizontal: 8,
            marginVertical: 4,
            paddingHorizontal: 16,
            paddingVertical: 16,
            gap: 4,
            shadowColor: '#000',
            shadowOffset: {
                width: 0,
                height: 1,
            },
            shadowOpacity: 0.25,
            shadowRadius: 5,
            elevation: 1,
            minWidth: 350,
        },
    });
    let status = appt.status ? appt.status[0].status_code : 'NONE';
    const statusTime = appt?.status?.[0].update_time;
    const fullname = appt.operator ? appt.operator.first_name + ' ' + appt.operator?.last_name : null;
    const desc =
        appt.work_type === 'PM'
            ? 'Preventative Maintenance Needed'
            : appt.work_type === 'CMPWO'
            ? 'Recall is Required'
            : 'Maintenance Required';

    if (status === 'VCOMP') status = 'COMPLETED';
    return (
        <TouchableOpacity
            style={[cardStyles.card, {borderLeftColor: getStatusColor(status)}]}
            onPress={() => gotoDetails(appt)}
            delayPressIn={100}
        >
            <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                <Text style={{fontWeight: 'bold', color: getStatusColor(status)}}>{status}</Text>
                {(status === 'COMPLETED' || status === 'VCOMP') && statusTime && (
                    <Text> - {new Date(statusTime).toDateString()}</Text>
                )}
            </View>
            <Subheading style={{fontWeight: '600'}}>{(appt.wonum ? appt.wonum : 'N/A') + ' - ' + desc}</Subheading>
            <List.Item
                title={appt.vehicle?.description ? appt.vehicle.description : 'No Vehicle Description'}
                description={appt.vehicle?.alias ? appt.vehicle.alias : 'No Vehicle Alias'}
                left={(props) => <MaterialCommunityIcons name='car-back' size={26} color='grey' />}
                style={{padding: 0}}
            />
            {appt.vendor_performed && (
                <Chip style={{backgroundColor: '#3F569F', alignSelf: 'flex-start'}} textStyle={{color: 'white'}}>
                    Vendor
                </Chip>
            )}
            {avatarVisible && <Avatar.Text size={36} label={fullname ? getInitials(fullname) : 'N/A'} />}
        </TouchableOpacity>
    );
};

export default AppointmentCard;
