import React from 'react';
import {Text, StyleSheet, ViewStyle, TouchableOpacity, ActivityIndicator} from 'react-native';
import {aepLightTheme as theme} from 'theme';

interface Props {
    title: string;
    fill?: boolean;
    fillColor?: string;
    borderColor?: string;
    textColor?: string;
    flex?: number;
    width?: number | string;
    height?: number;
    style?: ViewStyle;
    disabled?: boolean;
    loading?: boolean;
    onPress?: () => void;
}

const ActionButton = ({
    title,
    fill = true,
    fillColor = theme.colors.primary,
    borderColor,
    textColor = 'white',
    flex,
    width,
    style,
    loading = false,
    disabled = false,
    onPress,
}: Props) => (
    <TouchableOpacity
        style={[
            defaultStyles.wrapper,
            {
                backgroundColor: fill ? fillColor : 'transparent',
                borderColor: fill ? 'transparent' : borderColor,
                width,
                flex,
                ...style,
            },
            disabled ? {backgroundColor: theme.colors.disabled} : null,
            loading ? {backgroundColor: fill ? fillColor : 'transparent'} : null,
        ]}
        disabled={disabled}
        onPress={onPress}
    >
        {loading ? (
            <ActivityIndicator color={fill ? theme.colors.secondary : theme.colors.secondaryText} size={'large'} />
        ) : (
            <Text style={[{color: textColor}, disabled ? defaultStyles.textDisabled : defaultStyles.text]}>
                {title}
            </Text>
        )}
    </TouchableOpacity>
);

const defaultStyles = StyleSheet.create({
    wrapper: {
        padding: 8,
        borderRadius: 25,
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        height: 48,
        maxHeight: 48,
        maxWidth: 540,
        borderWidth: 1,
    },
    text: {
        textAlign: 'center',
        fontFamily: 'sans-serif',
        fontSize: 16,
        textTransform: 'capitalize',
    },
    textDisabled: {
        color: 'white',
        textAlign: 'center',
        fontFamily: 'sans-serif',
        fontSize: 16,
        textTransform: 'capitalize',
    },
});
export default ActionButton;
