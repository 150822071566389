import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import SegmentedControl from '@react-native-segmented-control/segmented-control';
import {useIsFocused} from '@react-navigation/native';
import {StackScreenProps} from '@react-navigation/stack';
import {APIService} from 'api';
import TopBar from 'components/TopBar';
import React, {useContext, useEffect, useState} from 'react';
import {View, StyleSheet, TouchableOpacity, FlatList} from 'react-native';
import {Avatar, List, Text, Subheading, useTheme, DataTable} from 'react-native-paper';
import {getInitials, PageTitle, Row} from 'theme';
import {flags, Vehicle} from 'shared/appt.model';
import {User, UserRole} from 'shared/auth.model';
import {AuthContext} from 'shared/context';
import SearchBar from 'components/SearchBar';

function VehicleCard({vehicle, gotoDetails}: {vehicle: Vehicle; gotoDetails: (v: Vehicle) => any}) {
    const [operator, setOperator] = useState<User>({
        first_name: 'Not',
        last_name: 'Available',
        id: -1,
        user_role: UserRole.OPERATOR,
    } as User);

    const {colors} = useTheme();
    const cardStyles = StyleSheet.create({
        card: {
            // borderLeftColor: 'gray',
            // borderLeftWidth: 6,
            flexGrowth: 0,
            flexBasis: 'auto',
            flexShrink: 0,
            backgroundColor: 'white',
            borderRadius: 4,
            marginHorizontal: 8,
            marginVertical: 4,
            paddingHorizontal: 16,
            paddingVertical: 16,
            gap: 4,
            shadowColor: '#000',
            shadowOffset: {
                width: 0,
                height: 1,
            },
            shadowOpacity: 0.25,
            shadowRadius: 5,
            elevation: 1,
            minWidth: 350,
        },
    });

    useEffect(() => {
        const api = new APIService();
        if (vehicle.operator)
            api.getUser(vehicle.operator).then((op) => {
                setOperator(op);
            });
    }, [vehicle.operator]);

    return (
        <TouchableOpacity style={[cardStyles.card]} onPress={() => gotoDetails(vehicle)} delayPressIn={100}>
            <Row alignment={'center'}>
                {vehicle.red_flag_track?.[0]?.is_flagged && (
                    <List.Item
                        title={flags[vehicle.red_flag_track[0]?.is_flagged]}
                        left={(props) => <MaterialCommunityIcons name='flag-outline' size={26} color={colors.accent} />}
                        style={{padding: 0}}
                        titleStyle={{color: colors.accent}}
                    />
                )}
            </Row>
            <Subheading style={{fontWeight: '600'}}>
                {vehicle.description ? vehicle.description : 'No Vehicle Description'}
            </Subheading>
            <List.Item
                title={vehicle.alias}
                left={(props) => <MaterialCommunityIcons name='car-back' size={26} color='grey' />}
                style={{padding: 0}}
            />
            <Row alignment={'center'} justify={'start'}>
                {operator && (
                    <Avatar.Text
                        size={36}
                        label={operator ? getInitials(operator.first_name + ' ' + operator.last_name) : 'N/A'}
                    />
                )}
                {vehicle.operator_assignment?.[0]?.status === 'PENDING' && (
                    <List.Item
                        title={'Review Change'}
                        left={(props) => (
                            <MaterialCommunityIcons name='information-outline' size={26} color={colors.accent} />
                        )}
                        style={{padding: 5}}
                        titleStyle={{color: colors.accent}}
                    />
                )}
            </Row>
        </TouchableOpacity>
    );
}

export function Vehicles({navigation}: StackScreenProps<any>) {
    const user = useContext(AuthContext).user;
    const isFocused = useIsFocused();
    const gotoDetails = (vehicle: Vehicle) => navigation.navigate('VehicleDetails', {vehicleId: vehicle.id});

    const [tabIndex, setTabIndex] = useState(0);
    const [vehicles, setVehicles] = useState<Vehicle[]>([]);
    const [pagedVehicles, setPagedVehicles] = useState<Vehicle[]>([]);

    const [searchQuery, setSearchQuery] = useState('');
    const [onSearch, setOnSearch] = useState(() => (query: string) => {
        setSearchQuery(query);
        setPageData({page: 1, pageCount: 1});
    });
    const [pageData, setPageData] = useState({page: 1, pageCount: 1});
    const requestsPerPage = 10;
    const [operators, setOperators] = useState<User[]>([]);

    useEffect(() => {
        const requestCount = vehicles.length;
        const pageCount = requestCount ? Math.ceil(requestCount / requestsPerPage) : 1;
        const page = Math.min(pageData.page, pageCount);
        const newVehicleList = vehicles.slice((page - 1) * requestsPerPage, page * requestsPerPage);
        setPagedVehicles(newVehicleList);
        setPageData({page, pageCount});
    }, [pageData.page, vehicles]);

    useEffect(() => {
        setOperators([user]);
    }, [isFocused, user]);

    useEffect(() => {
        const api = new APIService();
        if (tabIndex === 0)
            if (searchQuery) {
                api.searchVehicles(
                    operators.map((op) => op.id),
                    searchQuery
                ).then((v) => {
                    setVehicles(v);
                });
            } else {
                let vs: Vehicle[] = [];
                operators.forEach((op: any) => (vs = [...vs, ...op.vehicle]));
                setVehicles(vs);
            }
        else if (searchQuery)
            api.searchSupervisor(
                operators.map((op) => op.id),
                searchQuery
            ).then((v) => {
                setVehicles(v);
            });
        else
            api.searchSupervisor(
                operators.map((op) => op.id),
                ''
            ).then((v) => {
                setVehicles(v);
            });
    }, [tabIndex, operators, searchQuery]);

    return (
        <View style={styles.verticalContainer}>
            <TopBar />
            <PageTitle>My Vehicles</PageTitle>
            {user.user_role === UserRole.SUPERVISOR && (
                <View style={{paddingHorizontal: 16, paddingBottom: 8}}>
                    <SegmentedControl
                        appearance={'light'}
                        style={{height: 32}}
                        values={['Mine', 'Crew']}
                        selectedIndex={tabIndex}
                        onChange={(event) => {
                            setTabIndex(event.nativeEvent.selectedSegmentIndex);
                        }}
                    />
                </View>
            )}
            <View style={{paddingHorizontal: 24}}>
                <SearchBar onSearch={onSearch} />
                <DataTable.Pagination
                    page={pageData.page - 1}
                    numberOfPages={pageData.pageCount}
                    onPageChange={(page) => setPageData({...pageData, page: page + 1})}
                    selectPageDropdownLabel={'Page'}
                    label={`Page ${pageData.page} of ${pageData.pageCount}`}
                    showFastPaginationControls
                />
            </View>
            <View style={{flex: 1, alignItems: 'center'}}>
                <FlatList
                    data={pagedVehicles}
                    renderItem={({item}) => <VehicleCard vehicle={item} gotoDetails={gotoDetails} />}
                    keyExtractor={(item: Vehicle) => item.id.toString()}
                />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    verticalContainer: {
        flex: 1,
        backgroundColor: '#fff',
    },
});
