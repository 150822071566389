import {BaySize, GarageBay} from 'shared/garage.model';
import {User} from 'shared/auth.model';

export const apptStatusCodes = [
    'UNSCHEDULED',
    'CONFIRMED',
    'PENDING',
    'COMPLETED',
    'MISSED',
    'CANCELLED',
    'VCOMP',
] as const;
export type ApptStatusCode = typeof apptStatusCodes[number];

export interface ApptStatus {
    id: number;
    status_code: ApptStatusCode;
    update_time: string;
    update_by: string;
}
export interface Vehicle {
    id: number;
    description: string;
    alias: string;
    operator?: number;
    red_flag_track?: RedFlagTrack[];
    operator_assignment?: VehicleOperatorAssignment[];
    latest_pm_work?: Appointment;
    specification: string | null;
    pm_num: string | null;
    last_performed_pm: string | null;
    last_performed_pm_date: string | null;
    fleet_supervisor: number | null;
    scheduler: number | null;
    vehicle_rating: BaySize;
    additional_fields: string | null;
    assetnum: string;
    assetuid: number;
    update_time: string | null;
    sync_time: string | null;
    sync_status: string | null;
    license_num: string | null;
    default_garage_id: number | null;
    default_garage_description: string | null;
}

export const flags = {PMREDTAG: 'PM Red Tag', UNSAFETAG: 'Unsafe to Operate'} as const;
export type Flags = keyof typeof flags;
export interface RedFlagTrack {
    id: number;
    vehicle_id: number;
    is_flagged: Flags | null;
    reason: string | null;
    update_time: string;
    updated_by: number;
}

export type Status = 'PENDING' | 'CANCEL' | 'COMPLETE';
export interface VehicleOperatorAssignment {
    id: number;
    vehicle_id: number;
    operator: number | null;
    status: Status;
    memo: string | null;
    update_time: string;
    updated_by?: number;
}
export interface Appointment {
    id: number;
    approved_by_id: number | null;
    approved_time: string | null;
    description: string | null;
    due_date: string | null;
    duration: string | null;
    fleet_supervisor_id: number | null;
    garage_id: number | null;
    garage?: GarageBay | null;
    memo: string | null;
    operator_id: number | null;
    operator?: User;
    related_work?: ApptWork[];
    repair_code: string | null;
    scheduled_endtime: string | null;
    scheduled_starttime: string | null;
    status?: ApptStatus[];
    task_description: string | null;
    vehicle_id: number | null;
    vehicle?: Vehicle;
    vendor_performed: boolean | null;
    vendor_performed_work: VendorWork[];
    wonum: string | null;
    workorderid: string | null;
    work_type: string | null;
    aepsatellite: number;
}

export interface VendorWork {
    id: number;
    memo: string | null;
    update_time: string | null;
    updated_by: string | null;
    appt_id: number;
    sync_time: string | null;
    sync_status: string | null;
}

export interface ApptWork {
    id: number;
    appt_id: number;
    description: string | null;
    update_time: string | null;
    updated_by: string | null;
    sync_time: string | null;
    sync_status: string | null;
    classification_id: number | null;
}

export interface Classification {
    id: number;
    classification: string;
    description: string;
}
